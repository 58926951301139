import React,{useEffect,useState} from 'react';
import { useHistory } from "react-router-dom";

import api from 'app/modules/api';

import Icon from 'app/elements/Icon';
import Header from 'app/elements/Header';

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import Media from 'react-bootstrap/Media'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import ListGroup from 'react-bootstrap/ListGroup'
import InputGroup from 'react-bootstrap/InputGroup'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Nav from 'react-bootstrap/Nav'
import Modal from 'react-bootstrap/Modal'

const icons = {
  1: 'web',
  2: 'grade',
  3: 'cloud',
  4: 'folder',
  5: 'storage',
  6: 'email',
  7: 'public',
  8: 'vpn_key',
  9: 'font_download',
  10: 'laptop',
  11: 'device_hub',
  12: 'phone_iphone',
  18: 'forward_to_inbox'
}

const CustomersCredentials = ({customer})=>{
  const [loading,setLoading] = useState([]);
  const [categories,setCategories] = useState([]);
  const [data,setData] = useState([]);
  const [edit,setEdit] = useState(false);
  const [del,setDel] = useState(false);

  const load = (indicator,status=true)=>{
    if(typeof indicator === typeof ''){
      indicator = [indicator];
    }
    if(status){
      setLoading(l=>{
        l = [...l,...indicator];
        return l;
      });
    }else{
      setLoading(lo=>{
        let l = [...lo];
        l = l.filter(i=>indicator.indexOf(i) < 0);
        return l;
      });
    }
  }

  useEffect(()=>{
    loadTypes();
  },[]);
  useEffect(()=>{
    loadList();
    setEdit(false);
  },[customer]);

  const loadTypes = async()=>{
    load('types');
    const resp = await api.send('credentials/list_types');
    setCategories(resp.content);
    load('types',false);
  }
  const loadList = async()=>{
    load('list');
    const resp = await api.send('credentials/list',{customer_id:customer.id});
    setData(resp.content);
    load('list',false);
  }
  const deleteItem = async()=>{
    load('list');
    const d = del;
    setDel(false);
    await api.send('credentials/remove',{item_id:d});
    loadList();
  }

  return (
    <>
      <Header small={!edit}>
        <Container>
          <Media>
            <Media.Body><h2><Icon name="vpn_key"/> Zugangsdaten</h2></Media.Body>
            <Button variant={'success'} onClick={()=>{setEdit('new')}} disabled={!!edit}><Icon name="add"/></Button>
          </Media>
        </Container>
      </Header>
      <Container>
        {
          loading.indexOf('types') >= 0||loading.indexOf('list') >= 0 ? (
            <div className="text-center">
              <Spinner
                 as="span"
                 animation="border"
                 role="status"
                 aria-hidden="true"
                 variant="dark"
               />
             </div>
          ):(
            <>
              {!!edit&&!!categories.length&&(
                <EditCredentials item={edit} categories={categories} setEdit={setEdit} customer_id={customer.id} loadList={loadList} />
              )}
              {!!data.length?(
                <Tab.Container defaultActiveKey={'cat_'+data[0].type_id}>
                  <Row>
                    <Col lg={4} xl={3} className={'mb-2 mb-lg-0'}>
                      <Nav variant="pills" className="flex-column">
                        {categories.map(cat=>{
                          let items = data.filter(v=>v.type_id===cat.id);
                          if(items.length === 0){
                            return (null);
                          }
                          return (
                            <Nav.Item  key={'cat_'+cat.id} className={cat.customer!==null?'pl-3':''}>
                              <Nav.Link eventKey={'cat_'+cat.id}><Icon name={icons[cat.id]||'add'} className={'mr-2'}/>{cat.title}</Nav.Link>
                            </Nav.Item>
                          )
                        })}
                      </Nav>
                    </Col>
                    <Col lg={8} xl={9}>
                      <Tab.Content>
                        {categories.map(cat=>{
                          let items = data.filter(v=>v.type_id===cat.id);
                          if(items.length === 0){
                            return (null);
                          }
                          return (
                            <Tab.Pane eventKey={'cat_'+cat.id} key={'cat_pane_'+cat.id}>
                              {items.map((v,k)=>(
                                <CredentialsItem key={"credential_"+v.id} item={v} setEdit={setEdit} loadList={loadList} setDelete={setDel}/>
                              ))}
                            </Tab.Pane>
                          )
                        })}
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              ):(
                <Alert variant="info">Dieser Kunde hat bisher keine Zugangsdaten.</Alert>
              )}
            </>
          )
        }
      </Container>
      <Modal show={!!del} onHide={()=>{setDel(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>Daten löschen?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Die Daten können nicht wiederhergestellt werden.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{setDel(false)}}>
            Abbrechen
          </Button>
          <Button variant="danger" onClick={deleteItem}>
            Löschen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
const CredentialsItem = ({item,setEdit,loadList,setDelete})=>{
  let history = useHistory();

  const [visible,setVisible] = useState(false);
  const [downloading,setDownloading] = useState(false);
  const clipboard = text=>{
    navigator.clipboard.writeText(text);
  }
  const openLink = link=>{
    if(link.indexOf('http://') <0 && link.indexOf('https://') <0){
      link = 'https://'+link;
    }
    window.open(link);
  }
  const sendCredentials = (item)=>{
    history.push("/app/password",{title:item.title,content:item.content});
  }
  const downloadFile = async (id,name)=>{
    setDownloading(true);
    await api.download('credentials/file',{file_id:id},name);
    setDownloading(false);
  }

  return (
    <Card className={'mb-3'}>
      <Card.Header>
        <Media>
          <Media.Body>{item.title}</Media.Body>
          <ButtonGroup>
            <Button variant={'secondary'} size="sm" onClick={()=>sendCredentials(item)}><Icon name="send"/></Button>
            <Button variant={'secondary'} size="sm" onClick={()=>setEdit(item)}><Icon name="edit"/></Button>
            <Button variant={'secondary'} size="sm" onClick={()=>setDelete(item.id)}><Icon name="delete"/></Button>
          </ButtonGroup>
        </Media>
      </Card.Header>
      <ListGroup variant="flush">
        {item.content.map((i,k)=>(
          <ListGroup.Item key={"i_"+k}>
            <Row>
              <Col sm="3">{i.label}</Col>
              <Col sm="9">
                <Media>
                  <Media.Body>
                    <div style={{wordBreak:'break-all'}}>
                      {i.type==='file'?i.value.name:(i.type!=='password' || visible === k?i.value:'********')}
                    </div>
                  </Media.Body>
                  <ButtonGroup>
                    {i.type==='url'&&(
                      <Button variant={'secondary'} size="sm" onClick={()=>openLink(i.value)}><Icon name="link" size={1.2}/></Button>
                    )/* todo */}
                    {i.type==='password'&&(
                      <Button variant={'secondary'} size="sm" onClick={()=>setVisible(visible!==false?false:k)}><Icon name={visible === k?'visibility_off':'visibility'}/></Button>
                    )}
                    {i.type==='file'&&(
                      <Button variant={'secondary'} size="sm" onClick={()=>downloadFile(i.value.id,i.value.name)}>
                        {downloading?(
                          <Spinner
                           as="span"
                           animation="border"
                           size="sm"
                           role="status"
                           aria-hidden="true"
                         />
                        ):(<Icon name={'cloud_download'}/>)}
                      </Button>
                    )/* todo */}
                    {i.type!=='file'&&(
                      <Button variant={'secondary'} size="sm" onClick={()=>clipboard(i.value)}><Icon name="content_copy"/></Button>
                    )}
                  </ButtonGroup>
                </Media>
              </Col>
            </Row>

          </ListGroup.Item>
        ))}
      </ListGroup>
    </Card>

  );
}

const EditCredentials = ({item,categories,setEdit,customer_id,loadList})=>{
  const [loading,setLoading] = useState(false);

  const [fields,setFields] = useState(item === 'new'?[...categories[0].fields]:item.content);
  const [category,setCategory] = useState(item === 'new'?categories[0].id:item.type_id);
  const [title,setTitle] = useState(item === 'new'?'':item.title);

  const submit = async e=>{
    e.preventDefault();
    setLoading(true);

    let files = {};
    let file_count = 0;
    fields.forEach((v,k)=>{
      if(v.type === 'file'){
        if(item !== 'new' && !e.target['edit_field_'+k].files.length){
          fields[k].value = v.value.id;
        }else{
          files['file_'+file_count] = e.target['edit_field_'+k].files[0]
          fields[k].value = 'file_'+file_count;
          file_count++;
        }
      }
    });

    if(item === 'new'){
      await api.send('credentials/add',{title,category,fields:JSON.stringify(fields),customer_id},files);
    }else{
      await api.send('credentials/edit',{item_id:item.id,title,category,fields:JSON.stringify(fields)},files);
    }
    setLoading(false);
    setEdit(false);
    loadList();
  }

  const changeCategory = c=>{
    setCategory(parseInt(c));
    if(item === 'new'){
      categories.forEach(v=>{
        if(v.id === parseInt(c)){
          setFields(v.fields);
        }
      })
    }
  }
  const change = (key,field,value)=>{
    setFields(f=>{
      let fields = [...f];
      fields[key][field] = value;
      return fields;
    })
  }
  const changeLabel = (key,value)=>{
    change(key,'label',value);
  }
  const changeValue = (key,value)=>{
    change(key,'value',value);
  }
  const changeType = (key,value)=>{
    change(key,'type',value);
  }

  const array_move = (arr, old_index, new_index)=>{
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  const down = (k)=>{
    setFields(f=>{
      let fields = [...f];
      fields = array_move(fields,k,k+1);
      return fields;
    })
  }
  const up = (k)=>{
    setFields(f=>{
      let fields = [...f];
      fields = array_move(fields,k,k-1);
      return fields;
    })
  }
  const add = (k)=>{
    setFields(f=>{
      let fields = [...f];
      fields.splice(k+1,0,{label:'',value:'',type:'text'});
      return fields;
    })
  }
  const del = (k)=>{
    setFields(f=>{
      let fields = [...f];
      fields.splice(k,1);
      return fields;
    })
  }
  const generatePassword = (k)=>{
    setFields(f=>{
      let fields = [...f];
      fields[k].value = generatePw(30);
      return fields;
    })
  }

  const generatePw = (length)=>{
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789,.:;_-{}()[]!*+#";
    let retVal    = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  console.log(fields);

  return (
    <Form onSubmit={submit}>
      <Card className={'mb-3'}>
        <Card.Header>{title?title:'Titel'}</Card.Header>
        <Card.Body>
          <Form.Group as={Row} controlId="edit_title">
            <Form.Label column xl="2">
              Titel
            </Form.Label>
            <Col xl="10">
              <Form.Control type="text" value={title} onChange={e=>{setTitle(e.target.value)}} required disabled={loading} autoComplete="off"/>
            </Col>
          </Form.Group>
          <div className={'mb-3 mb-xl-0'}>
            <Form.Group as={Row} controlId="edit_category">
              <Form.Label column xl="2">
                Kategorie
              </Form.Label>
              <Col xl="10">
                <Form.Control as="select" value={category} onChange={e=>{changeCategory(e.target.value)}} required disabled={loading}>
                  {categories.filter((v,k)=>v.customer===null||v.customer===customer_id).map(cat=>(
                    <option value={cat.id} key={'cat_select_'+cat.id}>{(cat.customer!==null?'- ':'')+cat.title}</option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>
          </div>
          {fields.map((field,key)=>(
            <Row key={'field_'+key} className={'mb-3 mb-xl-0'}>
              <Col xl="2">
                <Form.Group controlId={'edit_label_'+key}>
                  <Form.Control value={field.label} onChange={e=>changeLabel(key,e.target.value)} required disabled={loading} autoComplete="off"/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId={'edit_field_'+key}>
                  {field.type === 'textarea' && (
                    <Form.Control value={field.value} as={field.type} onChange={e=>changeValue(key,e.target.value)} disabled={loading} required autoComplete="off"/>
                  )}
                  {field.type === 'password' && (
                    <InputGroup>
                      <Form.Control value={field.value} type={'text'} onChange={e=>changeValue(key,e.target.value)} disabled={loading} required autoComplete="off"/>
                      <InputGroup.Append>
                        <Button onClick={()=>{generatePassword(key)}} variant="secondary" disabled={loading}><Icon name={'sync'}/></Button>
                      </InputGroup.Append>
                    </InputGroup>
                  )}
                  {field.type === 'file' && (
                    <Form.File
                     label={field.value?(typeof field.value === typeof []?field.value.name:field.value):"Keine Datei gewählt…"}
                     data-browse="wählen"
                     lang="de"
                     custom
                     onChange={e=>changeValue(key,e.target.value)}
                     disabled={loading}
                     required={item === 'new'}
                   />
                  )}
                  {field.type !== 'textarea' && field.type !== 'password' && field.type !== 'file' && (
                    <Form.Control value={field.value} type={field.type==='url'?'text':field.type} onChange={e=>changeValue(key,e.target.value)} disabled={loading} required autoComplete="off"/>
                  )}
                </Form.Group>
              </Col>
              <Col xl="4">
                <Form.Group controlId={'edit_type_'+key}>
                  <InputGroup>
                    <Form.Control as="select" value={field.type} onChange={e=>changeType(key,e.target.value)} disabled={loading}>
                      <option value="text">Text</option>
                      <option value="password">Passwort</option>
                      <option value="textarea">Langer Text</option>
                      <option value="url">Link</option>
                      <option value="number">Nummer</option>
                      <option value="file">Datei</option>
                    </Form.Control>
                    <InputGroup.Append>
                      <DropdownButton variant={'secondary'} id="dropdown-basic-button" title={(<Icon name={'settings'}/>)}>
                        <Dropdown.Item onClick={()=>{up(key)}} disabled={key===0 || loading}>nach oben</Dropdown.Item>
                        <Dropdown.Item onClick={()=>{down(key)}} disabled={key===fields.length-1|| loading}>nach unten</Dropdown.Item>
                        <Dropdown.Item onClick={()=>{add(key)}} disabled={loading}>neue Zeile danach</Dropdown.Item>
                        <Dropdown.Item onClick={()=>{del(key)}} disabled={loading}>löschen</Dropdown.Item>
                      </DropdownButton>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          ))}
        </Card.Body>
        <Card.Footer>
          <Button variant="primary" type="submit">
            Speichern
          </Button>
          {' '}
          <Button variant="secondary" onClick={()=>{setEdit(false)}}>
            Abbrechen
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  )
}

export default CustomersCredentials;