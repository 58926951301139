import React,{useState,useEffect} from 'react';
import {
  BrowserRouter,
  Switch,
  Redirect,
  Route
} from "react-router-dom";
import Cookies from 'js-cookie'
import api from 'app/modules/api';

import 'assets/css/loading.scss';

import Login from 'app/wrapper/Login';
import App from 'app/wrapper/App';
import NoMatch from 'app/wrapper/NoMatch';

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'

const Router = ()=>{
  const [loading,setLoading] = useState(true);
  const [auth,setAuth] = useState(false);
  const [vpnError,setVpnError] = useState(false);

  const checkAuth = async ()=>{
    setLoading(true);
    const token = JSON.parse(Cookies.get("if_token")||null);
    if(token === null){
      api.setAuth(false);
      setAuth(false);
      setLoading(false);
      return;
    }
    api.setAuth(token);
    const auth = await api.send('user/auth');
    if(auth.error !== 0){
      if(auth.error === 100){
        setVpnError(true)
      }
      api.setAuth(false);
      setAuth(false);
      setLoading(false);
      return;
    }

    setAuth(true);
    setLoading(false);
  }

  useEffect(()=>{
    checkAuth();
  },[]);

  if(loading){
    return (
      <div className="loading">
        <Spinner
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        />
      </div>
    )
  }

  if(vpnError){
    return (
      <Container className={'my-3'}>
        <Alert variant="dark">Zugriff verweigert!</Alert>
      </Container>
    )
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login">
          {!!auth?(
            <Redirect to={{pathname: "/app"}}/>
          ):(
            <Login checkAuth={checkAuth}/>
          )}
        </Route>
        <Route path="/app">
          {!auth?(
            <Redirect to={{pathname: "/login"}}/>
          ):(
            <App checkAuth={checkAuth}/>
          )}
        </Route>
        <Route exact path="/">
          {!!auth?(
            <Redirect to={{pathname: "/app"}}/>
          ):(
            <Redirect to={{pathname: "/login"}}/>
          )}
        </Route>
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default Router;
