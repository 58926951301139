import React from 'react';

import Navbar from 'react-bootstrap/Navbar'

import 'assets/css/error-404.scss';

const NoMatch = ({checkAuth,nowrapper=false})=>{
  return (
    <div className={!nowrapper?"app":""}>
      {!nowrapper&&(
        <Navbar bg="white" variant="light" expand="lg" fixed="top" className={'shadow'}>
          <Navbar.Brand className="ml-auto mr-auto">ideenfrische</Navbar.Brand>
        </Navbar>
      )}
      <div className={"error-404"}>
        <div className="error-404__inner">
          <div className="error-404__headline">404</div>
          <div className="error-404__subline">Page not found</div>
        </div>
      </div>
    </div>
  )
}


export default NoMatch;