import React,{useState} from 'react';
import api from 'app/modules/api';

import Icon from 'app/elements/Icon';
import Header from 'app/elements/Header';

import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'

const CustomersInfo = ({customer,setCustomer,loadCustomers})=>{
  const [loading,setLoading] = useState(false);
  const [error,setError] = useState(false);
  const [del,setDel] = useState(false);

  const submit = async (e)=>{
    e.preventDefault();
    setLoading(true);
    setError(false);
    const resp = await api.send('customers/edit',{id:customer.id,url:e.target.url.value,basecamp:e.target.basecamp.value})
    if(resp.error === 0){
      loadCustomers();
      setCustomer({id:customer.id,url:resp.content});
    }else{
      setError(true);
    }

    setLoading(false);
  }

  const deleteCustomer = (e)=>{
    e.preventDefault();
    setDel(true);
  }
  const closeDeleteModal = ()=>{
    setDel(false);
  }
  const deleteItem = async ()=>{
    setDel(false);
    setLoading(true);
    const resp = await api.send('customers/remove',{id:customer.id})
    if(resp.error === 0){
      loadCustomers();
      setCustomer(false);
    }else{
      setLoading(false);
    }
  }

  return (
    <>
      <Header>
        <Container>
          <h2><Icon name="face"/> Kunden verwalten</h2>
        </Container>
      </Header>
      <Container>
        {error&&(
          <Alert variant="danger">Es ist ein Fehler aufgetreten</Alert>
        )}
        <Form onSubmit={submit}>
          <Card>
            <Card.Header>{customer.url}</Card.Header>
            <Card.Body>
              <Form.Group controlId="url">
                <Form.Label>Web-Adresse</Form.Label>
                <Form.Control autoComplete="off" type="text" required disabled={loading} defaultValue={customer.url}/>
              </Form.Group>
              <Form.Group controlId="basecamp">
                <Form.Label>Basecamp-ID</Form.Label>
                <Form.Control autoComplete="off" type="text" disabled={loading} defaultValue={customer.basecamp}/>
              </Form.Group>
            </Card.Body>
            <Card.Footer>
              <Button variant="primary" type={'submit'} disabled={loading}>Speichern</Button>
              {' '}
              <Button variant="danger" onClick={deleteCustomer} disabled={loading}>Löschen</Button>
            </Card.Footer>
          </Card>
        </Form>
      </Container>
      <Modal show={del} onHide={closeDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Daten löschen?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Die Daten können nicht wiederhergestellt werden.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteModal}>
            Abbrechen
          </Button>
          <Button variant="danger" onClick={deleteItem}>
            Löschen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default CustomersInfo;