import api from 'app/modules/api';

const notifications = ()=>{
  let supported = false;
  let data = [];
  let send = [];

  const init = ()=>{
    Notification.requestPermission().then(function(result) {
      if(result === 'granted'){
        supported = true;
      }
    });
  }

  const watch = ()=>{
    setTimeout(()=>{
      loadUnread();
      setInterval(()=>{
        loadUnread();
      },20000);
    },5000);
  }

  const loadUnread = async ()=>{
    if(api.isAuth()){
      const resp = await api.send('notification/unread');
      if(resp.error === 0){
        data = resp.content;
        doListener(data);
      }
    }
  }

  let listeners = [];
  const addListener = (fct)=>{
    listeners.push(fct);
    return listeners.length -1;
  }
  const removeListener = (fct_id)=>{
    listeners[fct_id] = null;
  }
  const doListener = (d)=>{
    listeners.forEach(fct=>{
      if(fct !== null){
        fct(d);
      }
    });
  }

  const getText = (v)=>{
    switch(v.type){
      case 'vacation_request':
        return v.from_name+' hat einen Urlaubsantrag gestellt.';
      case 'vacation_confirmed':
        return v.from_name+' hat deinen Urlaubsantrag freigegeben.';
      default:
        return 'Benachrichtigung!'
    }

  }

  const sendNotification = (v)=>{
    let n = new Notification(getText(v), {
      data: v,
      tag: v.id
    });
    n.onclick = ()=>{
      n.close();
      onClick(v);
    };
  }

  const onClick = async (v)=>{
    if(v.read === "0" && v.id){
      await api.send('notification/read',{id:v.id});
    }
    switch(v.type){
      case 'vacation_request':
      case 'vacation_confirmed':
        goTo("/app/vacation");
        break;
      default:
        break;
    }
    loadUnread();
  }

  const goTo = (p)=>{
    if(window.location.pathname !== p){
      window.location = p;
    }
  }

  const sendLoadedNotifications = (d)=>{
    if(supported){
      d.forEach(v=>{
        if(send.indexOf(v.id) < 0){
          sendNotification(v);
          send.push(v.id);
        }
      });
    }
  }
  addListener(sendLoadedNotifications);


  init();
  watch();
  return {supported,addListener,removeListener,getData:()=>{return data},onClick,getText}
}
export default notifications();
