import moment from 'moment';
import moment_de from 'moment/locale/de';

import React,{useEffect,useState} from 'react';

import Header from 'app/elements/Header'
import Icon from 'app/elements/Icon'

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import Toast from 'react-bootstrap/Toast'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import {Status as Timelog} from 'app/screens/Timelog'
import {Status as Vacation} from 'app/screens/Vacation'
import {Overview as TimetrackingOverview} from 'app/screens/CustomersTimetracking'

import api from 'app/modules/api';

const Dashboard = ()=>{
    return (
      <>
        <Header>
          <Container>
            <h2>Dashboard</h2>
          </Container>
        </Header>
        <Container>
          <Row>
            <Col xl={9}>
              <Row>
                <Col xl={6} className={'mb-3'}>
                  <Timelog />
                  <Vacation className={'mt-3'}/>
                </Col>
                <Col xl={6} className={'mb-3'}>
                  <Timetracking className={'mb-3'}/>
                  <TimetrackingOverview/>
                </Col>
              </Row>
            </Col>
            <Col xl={3}>
              <Basecamp/>
            </Col>
          </Row>
        </Container>
      </>
    )
}

const Timetracking = ({className})=>{
  const [loading,setLoading] = useState(false);
  const [data,setData] = useState(false);

  useEffect(()=>{
    loadTimetracking();
  },[]);

  const stop = async ()=>{
    setLoading(true);
    await api.send('timetracking/stop',{item_id:data.item_id});
    loadTimetracking()
  }

  const loadTimetracking = async ()=>{
    setLoading(true);
    const timetracking = await api.send('timetracking/status');
    setData(timetracking.content);
    setLoading(false);
  }
  if(loading || !data || !data.tracking){
    return (null);
  }
  return (
    <div className={className}>
      <Card>
        <Card.Header>Timetracking - {data.customer_url}</Card.Header>
        <Card.Body><strong>{data.item_title}</strong> gestartet seit <strong>{moment(data.start).format('lll')}</strong></Card.Body>
        <Card.Footer>
          <Button variant={'pink'} onClick={stop}><Icon name="pause"/> Stoppen</Button>
        </Card.Footer>
      </Card>
    </div>
  )
}

const Basecamp = ()=>{
  const [loading,setLoading] = useState(false);
  const [data,setData] = useState([]);

  useEffect(()=>{
    moment.locale('de',moment_de);
    loadBasecamp();
  },[]);

  const loadBasecamp = async ()=>{
    setLoading(true);
    const basecamp = await api.send('basecamp/get',{limit:5});
    setData(basecamp.content);
    setLoading(false);
  }

  if(loading){
    return (
      <div className="text-center">
        <Spinner
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
          variant="light"
        />
      </div>
    )
  }

  return (
    <div>
      {data.map((v,k)=>(
        <div role={'button'} className={'mb-1'} key={v.id}>
          <Toast onClick={()=>window.open(v.html_url)}>
            <Toast.Header closeButton={false}>
              <img src={v.creator.avatar_url} className="rounded mr-2" alt="" width={24} height={24} />
              <strong className="mr-auto ">{v.bucket.name}</strong>
            </Toast.Header>
            <Toast.Body>
              <div dangerouslySetInnerHTML={{ __html: v.creator.name+" "+v.summary }}></div>
              <div className="text-right">
                <small>{moment(v.created_at).fromNow()}</small>
              </div>
            </Toast.Body>
          </Toast>
        </div>
      ))}
    </div>
  );
}

export default Dashboard;