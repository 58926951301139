import React,{useEffect,useState,useRef} from 'react';
import moment from 'moment';

import api from 'app/modules/api';

import Icon from 'app/elements/Icon';
import Header from 'app/elements/Header';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Accordion from 'react-bootstrap/Accordion'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Media from 'react-bootstrap/Media'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'
import Tooltip from 'react-bootstrap/Tooltip'
import Overlay from 'react-bootstrap/Overlay'
import InputGroup from 'react-bootstrap/InputGroup'

import "assets/css/react-widgets.scss";

moment.locale('de');

const getDuration = (a) => {
  let s = Math.abs(a);
  let minutes = s / 60;
  let hours   = Math.floor(minutes / 60);
  minutes = Math.round(minutes - (hours*60));
  return (a<0?'-':'')+hours+'h '+minutes+'m';
}

const CustomersTimetracking = ({customer,loadCustomers,customers})=>{
  const [loading,setLoading] = useState([]);
  const [del,setDel] = useState(false);
  const [arch,setArch] = useState(false);
  const [status,setStatus] = useState(false);
  const [list,setList] = useState([]);
  const [editTime,setEditTime] = useState(false);

  const [addType,setAddType] = useState(1);

  const load = (indicator,status=true)=>{
    if(typeof indicator === typeof ''){
      indicator = [indicator];
    }
    if(status){
      setLoading(l=>{
        l = [...l,...indicator];
        return l;
      });
    }else{
      setLoading(lo=>{
        let l = [...lo];
        l = l.filter(i=>indicator.indexOf(i) < 0);
        return l;
      });
    }
  }

  const editItem = async (e,item_id)=>{
    e.preventDefault();
    load(['edit_item']);

    let data = {
      item_id,
      title:e.target.description_edit.value,
      customer:e.target.customer_edit.value,
      note: e.target.note_edit.value
    }

    if(e.target?.time_hours_edit?.value && e.target?.time_minutes_edit?.value){
      data.time = ((parseInt(e.target.time_hours_edit.value)*60) + parseInt(e.target.time_minutes_edit.value))*60;
    }
    if(e.target?.price_edit?.value){
      data.price = e.target.price_edit.value;
    }

    await api.send('timetracking/edit',data);
    await loadList();
    load(['edit_item'],false);
  }

  const closeDeleteModal = ()=>{
    setDel(false);
  }
  const deleteItem = async ()=>{
    const d = [...del];
    setDel(false);

    load(['list','status']);
    if(d[0] === 'item'){
      await api.send('timetracking/remove',{item_id:d[1]});
    }else if(d[0] === 'time'){
      await api.send('timetracking/remove_time',{time_id:d[1]});
    }
    loadStatus();
    loadList();
    loadCustomers();
  }

  const addItem = async (e)=>{
    const target = e.target;
    e.preventDefault();
    load('new_item');

    var data = {
      customer:customer.id,
      title:target.description.value,
      type:target.type.value,
      note:target.note.value
    }
    if(parseInt(data.type)===2){
      data.time = ((parseInt(target.time_hours.value)*60) + parseInt(target.time_minutes.value))*60;
    }else if(parseInt(data.type)===3){
      data.price = parseInt(target.price.value);
    }
    await api.send('timetracking/add',data);
    target.description.value = '';
    target.note.value = '';
    setAddType(1);
    await loadList();
    load('new_item',false);
  }

  const loadStatus = async ()=>{
    load('status');
    const resp = await api.send('timetracking/status',{customer_id:customer.id});
    setStatus(resp.content);
    load('status',false);
  }
  const loadList = async ()=>{
    load('list');
    const resp = await api.send('timetracking/list',{customer_id:customer.id});
    setList(resp.content);
    load('list',false);
  }

  const toggleItem = async (id,start)=>{
    let toggle=[false,false];
    list.forEach((v,k)=>{
      if(v.active){
        toggle[1] = v.id;
      }
      if(start && v.id === id){
        toggle[0] = v.id
      }
    });
    load(toggle[1]?['list','status']:['list']);
    if(toggle[1]){
      await api.send('timetracking/stop',{item_id:toggle[1]});
      loadStatus();
      loadCustomers();
    }
    if(toggle[0]){
      await api.send('timetracking/start',{item_id:toggle[0]});
    }
    loadList();
  }

  const archive = (item_id,archiveStatus=1,force=false)=>{
    if(archiveStatus !== 1 || status.basecamp_status ||force){
      archive_request(item_id,archiveStatus);
      setArch(false);
      return;
    }
    setArch([item_id,archiveStatus]);
  }
  const archive_request = async (item_id,status=1)=>{
    load('archive');
    if(typeof item_id === typeof [] && item_id[0] === 'all'){
      await api.send('timetracking/completeall',{id: item_id[1],status});
    }else{
      await api.send('timetracking/complete',{item_id,status});
    }
    load(['list','status']);
    loadStatus();
    loadList();
    loadCustomers();
    load('archive',false);
  }

  const submitEditTimte = async (start,stop)=>{
    load(['edit_time']);
    await api.send('timetracking/edit_time',{time_id:editTime,start,stop})
    setEditTime(false);
    load(['list','status']);
    loadStatus();
    loadList();
    loadCustomers();
    load(['edit_time'],false);
  }

  useEffect(()=>{
    loadStatus();
    loadList();
  },[customer]);

  const getTime = (s,date=false)=>{
    const t = moment(s);
    return t.format(date?'L':'LT');
  }

  const archiveAll = ()=>{
    archive(['all',customer.id],1);
  }

  return (
    <>
      <Header>
        <Container>
          <Media>
            <Media.Body><h2><Icon name="access_alarm"/> Timetracking</h2></Media.Body>
            {loading.indexOf('list') < 0 && !!list.filter((item)=>!item.archived).length && (
              <Button variant={'success'} onClick={()=>{archiveAll()}} disabled={loading.indexOf('archive') >= 0}>Alle archivieren</Button>
            )}
          </Media>
        </Container>
      </Header>
      <Container>
        <Row>
          <Col xl={5}>
            <Form onSubmit={addItem}>
             <Card className={'mb-3'}>
              <Card.Header>{customer.url}</Card.Header>
              <Card.Body>
                <Form.Group controlId="description">
                  <Form.Label>Beschreibung</Form.Label>
                  <Form.Control type="text" required disabled={loading.indexOf('new_item') >= 0}  autoComplete="off"/>
                </Form.Group>
                <Form.Group controlId="type">
                  <Form.Label>Art</Form.Label>
                  <Form.Control as="select" required disabled={loading.indexOf('new_item') >= 0}  autoComplete="off" value={addType} onChange={e=>setAddType(e.target.value)}>
                    <option value="1">Timetracking</option>
                    <option value="2">Pauschal - Zeit</option>
                    <option value="3">Pauschal - Euro</option>
                  </Form.Control>
                </Form.Group>
                {parseInt(addType)===2&&(
                  <Row>
                    <Col>
                      <Form.Group controlId="time_hours">
                        <Form.Label>Stunden</Form.Label>
                        <Form.Control type="number" min="0" step="1" required disabled={loading.indexOf('new_item') >= 0}  autoComplete="off" defaultValue="0"/>
                      </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group controlId="time_minutes">
                      <Form.Label>Minuten</Form.Label>
                      <Form.Control type="number" min="0" max="59" step="1" required disabled={loading.indexOf('new_item') >= 0}  autoComplete="off" defaultValue="0"/>
                    </Form.Group>
                    </Col>
                  </Row>
                )}
                {parseInt(addType)===3&&(
                  <Form.Group controlId="price">
                    <Form.Label>Preis</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>€</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control type="number" min="0" step="1" required disabled={loading.indexOf('new_item') >= 0}  autoComplete="off" defaultValue="0"/>
                    </InputGroup>
                  </Form.Group>
                )}
                <Form.Group controlId="note">
                  <Form.Label>Notiz</Form.Label>
                  <Form.Control as="textarea" disabled={loading.indexOf('new_item') >= 0}  autoComplete="off"/>
                </Form.Group>
              </Card.Body>
              <Card.Footer>
                <Button variant='primary' type="submit" disabled={loading.indexOf('new_item') >= 0}>Hinzufügen</Button>
              </Card.Footer>
             </Card>
           </Form>
           {loading.indexOf('status') >= 0 && (
             <div className='text-center'>
               <Spinner
                 as="span"
                 animation="border"
                 role="status"
                 aria-hidden="true"
                 variant="dark"
               />
             </div>
           )}
           {loading.indexOf('status') < 0 && (
             <Card className={'mb-3'}>
              <Card.Header>Status</Card.Header>
              <ListGroup as="ul" variant="flush">
                <ListGroup.Item as="li">
                  <Media>
                    <Media.Body>Zu verrechnen:</Media.Body>
                    {!!status.time_active && !!status.price_active && (
                      <>{getDuration(status.time_active)} + {status.price_active} €</>
                    )}
                    {!!status.time_active && !status.price_active && (
                      <>{getDuration(status.time_active)}</>
                    )}
                    {!status.time_active && !!status.price_active && (
                      <>{status.price_active} €</>
                    )}
                    {!status.time_active && !status.price_active && (
                      <>-</>
                    )}
                  </Media>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <Media>
                    <Media.Body>Alles:</Media.Body>
                    {!!status.time_all && !!status.price_all && (
                      <>{getDuration(status.time_all)} + {status.price_all} €</>
                    )}
                    {!!status.time_all && !status.price_all && (
                      <>{getDuration(status.time_all)}</>
                    )}
                    {!status.time_all && !!status.price_all && (
                      <>{status.price_all} €</>
                    )}
                    {!status.time_all && !status.price_all && (
                      <>-</>
                    )}
                  </Media>
                </ListGroup.Item>
                {!!status.basecamp_status && (
                  <ListGroup.Item as="li">
                    <a href={"https://basecamp.com/1798908/projects/"+customer.basecamp+"/documents/"+status.basecamp_status} target="_blank" rel="noopener noreferrer">zum Basecamp Dokument</a>
                  </ListGroup.Item>
                )}
              </ListGroup>
              {!status.basecamp_status && (
                <Card.Body>
                  <Alert variant="danger">Es besteht keine Verknüpfung zu Basecamp. Daten werden beim archivieren nicht übertragen!</Alert>
                </Card.Body>
              )}
             </Card>
           )}
          </Col>
          <Col>
            {loading.indexOf('list') >= 0 &&(
              <div className='text-center'>
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  variant="light"
                />
              </div>
            )}
            {loading.indexOf('list') < 0 && !list.length && (
              <Alert variant={'info'}>
               Im Moment sind keine Trackings angelegt.
             </Alert>
            )}
            {loading.indexOf('list') < 0 && !!list.length && (
              <>
                {!!list.filter((item)=>!item.archived).length && (
                  <Accordion className={'mb-3'}>
                    {list.filter((item)=>!item.archived).map((item,k)=>{
                      return (
                        <Card key={"tracking_"+item.id}>
                          <Card.Header className="align-middle">
                            <Media>
                              <Media.Body>
                                <Title item={item}/>
                              </Media.Body>
                              <strong className="mr-3">
                              {item.type === 3 ? item.price + ' €':(
                                <LiveDuration
                                  duration={item.sum}
                                  started={item.started}
                                  getDuration={getDuration}
                                />
                              )}
                              </strong>
                              <ButtonGroup>
                                {item.type === 1 && (
                                  <>
                                    <Button size="sm" variant={item.active?"pink":"dark"} onClick={()=>{toggleItem(item.id,item.active?false:true)}} disabled={item.archived}>
                                      {item.active?(
                                        <Icon name="pause"/>
                                      ):(
                                        <Icon name="play_arrow"/>
                                      )}
                                    </Button>
                                    <Accordion.Toggle as={Button} eventKey={k+'_2'} size="sm"  variant="secondary">
                                      <Icon name="list"/>
                                    </Accordion.Toggle>
                                  </>
                                )}
                                <Accordion.Toggle as={Button} eventKey={k+'_1'} size="sm"  variant="secondary">
                                  <Icon name="settings"/>
                                </Accordion.Toggle>
                                <Button size="sm" variant="secondary" onClick={()=>archive(item.id,!item.archived?1:0)} disabled={loading.indexOf('archive') >= 0}>
                                  {item.archived?(
                                    <Icon name="redo"/>
                                  ):(
                                    <Icon name="check"/>
                                  )}
                                </Button>
                                <Button size="sm" variant="secondary" onClick={()=>setDel(['item',item.id])}>
                                  <Icon name="delete"/>
                                </Button>
                              </ButtonGroup>
                            </Media>
                          </Card.Header>
                          {item.type === 1 && (
                            <Accordion.Collapse eventKey={k+'_2'}>
                              <>
                                <ListGroup variant="flush">
                                  {item.times.map((v1,k1)=>{
                                    return (
                                      <ListGroup.Item key={'time_'+v1.id}>
                                        {v1.id === editTime && (
                                          <TimeEditForm
                                            submitEditTimte={submitEditTimte}
                                            v1={v1}
                                            loading={loading}
                                            setEditTime={setEditTime}
                                          />
                                        )}
                                        {v1.id !== editTime && (
                                          <Media>
                                            <Media.Body>{getTime(v1.start,true)} <strong>{getTime(v1.start)} - {getTime(v1.stop)}</strong></Media.Body>
                                            <strong className="mr-3">{getDuration(v1.duration)}</strong>
                                            <ButtonGroup>
                                              <Button size="sm" variant="light" onClick={()=>setEditTime(v1.id)}>
                                                <Icon name="create"/>
                                              </Button>
                                              <Button size="sm" variant="light" onClick={()=>setDel(['time',v1.id])}>
                                                <Icon name="delete"/>
                                              </Button>
                                            </ButtonGroup>
                                          </Media>
                                        )}
                                      </ListGroup.Item>
                                    )
                                  })}
                                </ListGroup>
                              </>
                            </Accordion.Collapse>
                          )}
                          <Accordion.Collapse eventKey={k+'_1'}>
                            <Card.Body>
                              <Form onSubmit={e=>editItem(e,item.id)}>
                                <Form.Group controlId="description_edit">
                                  <Form.Label>Beschreibung</Form.Label>
                                  <Form.Control type="text" required disabled={loading.indexOf('edit_item') >= 0} defaultValue={item.title} autoComplete="off"/>
                                </Form.Group>

                                {parseInt(item.type)===2&&(
                                  <Row>
                                    <Col>
                                      <Form.Group controlId="time_hours_edit">
                                        <Form.Label>Stunden</Form.Label>
                                        <Form.Control type="number" min="0" step="1" required disabled={loading.indexOf('edit_item') >= 0}  autoComplete="off" defaultValue={Math.floor(item.sum/60/60)}/>
                                      </Form.Group>
                                    </Col>
                                    <Col>
                                    <Form.Group controlId="time_minutes_edit">
                                      <Form.Label>Minuten</Form.Label>
                                      <Form.Control type="number" min="0" max="59" step="1" required disabled={loading.indexOf('edit_item') >= 0}  autoComplete="off" defaultValue={item.sum/60 - (Math.floor(item.sum/60/60) * 60)}/>
                                    </Form.Group>
                                    </Col>
                                  </Row>
                                )}

                                {parseInt(item.type)===3&&(
                                  <Form.Group controlId="price_edit">
                                    <Form.Label>Preis</Form.Label>
                                    <InputGroup>
                                      <InputGroup.Prepend>
                                        <InputGroup.Text>€</InputGroup.Text>
                                      </InputGroup.Prepend>
                                      <Form.Control type="number" min="0" step="1" required disabled={loading.indexOf('edit_item') >= 0}  autoComplete="off" defaultValue={item.price}/>
                                    </InputGroup>
                                  </Form.Group>
                                )}

                                <Form.Group controlId="note_edit">
                                  <Form.Label>Notiz</Form.Label>
                                  <Form.Control as="textarea" disabled={loading.indexOf('edit_item') >= 0} defaultValue={item.note} autoComplete="off"/>
                                </Form.Group>
                                <Form.Group controlId="customer_edit">
                                  <Form.Label>Verschieben</Form.Label>
                                  <Form.Control as="select" disabled={loading.indexOf('edit_item') >= 0} defaultValue={customer.id}>
                                    {customers.map((v1,k1)=>(<option value={v1.id} key={'customeredit_'+v1.id}>{v1.url}</option>))}
                                  </Form.Control>
                                </Form.Group>
                                <Button variant='primary' type="submit" disabled={loading.indexOf('edit_item') >= 0}>Ändern</Button>
                              </Form>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      )
                    })}
                  </Accordion>
                )}
                {!!list.filter((item)=>!!item.archived).length && (
                  <Accordion className={'mb-3'}>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey={'archive'} className="align-middle cursor-pointer">
                        Archivierte Trackings
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={'archive'}>
                        <ListGroup variant="flush">
                          {list.filter((item)=>!!item.archived).map((item,k)=>{
                            return (
                              <ListGroup.Item key={"tracking_"+item.id}>
                                <Media>
                                  <Media.Body>
                                    <Title item={item}/>
                                  </Media.Body>
                                  <strong className="mr-3">
                                    {item.type === 3 ? item.price + ' €':(
                                      <LiveDuration
                                        duration={item.sum}
                                        started={item.started}
                                        getDuration={getDuration}
                                      />
                                    )}
                                  </strong>
                                  <ButtonGroup>
                                    <Button size="sm" variant="secondary" onClick={()=>archive(item.id,!item.archived?1:0)} disabled={loading.indexOf('archive') >= 0}>
                                      <Icon name="redo"/>
                                    </Button>
                                    <Button size="sm" variant="secondary" onClick={()=>setDel(['item',item.id])}>
                                      <Icon name="delete"/>
                                    </Button>
                                  </ButtonGroup>
                                </Media>
                              </ListGroup.Item>
                            )
                          })}
                        </ListGroup>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
      <Modal show={del} onHide={closeDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Daten löschen?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Die Daten können nicht wiederhergestellt werden.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteModal}>
            Abbrechen
          </Button>
          <Button variant="danger" onClick={deleteItem}>
            Löschen
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={arch} onHide={()=>{setArch(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>Keine Basecamp-Datei</Modal.Title>
        </Modal.Header>
        <Modal.Body>Es ist keine Basecamp-Datei hinterlegt, in welche die Daten übertragen werden können. Trotzdem archivieren?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{setArch(false)}}>
            Abbrechen
          </Button>
          <Button variant="danger" onClick={()=>{archive(arch[0],arch[1],true)}}>
            Archivieren
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const Title = ({item})=>{
  const [show, setShow] = useState(false);
  const target = useRef(null);
  return (
    <>

      {!!item.note&&(
        <>
          <Button size="sm" variant="info" className="mr-2" ref={target} onClick={() => setShow(!show)}><Icon name="info"/></Button>
          <Overlay target={target.current} show={show} placement="right">
            {(props) => (
              <Tooltip {...props}>
                {item.note}
              </Tooltip>
            )}
          </Overlay>
        </>
      )}
      {item.title}
    </>
  )
}

const LiveDuration = ({duration,started,getDuration})=>{
  const [update,setUpdate] = useState(new Date().toString());

  useState(()=>{
    if(started){
      let interval = setInterval(()=>{
        setUpdate(new Date().toString());
      },5000);
      return ()=>clearInterval(interval);
    }
  },[started]);

  let startedtime = 0;
  if(started){
    startedtime = (new Date() - new Date(started)) / 1000;
  }

  return getDuration(duration + startedtime);
}

const TimeEditForm = ({submitEditTimte,v1,loading,setEditTime})=>{
  let startTemp = new Date(v1.start);
  let stopTemp = new Date(v1.stop);
  startTemp.setMinutes(startTemp.getMinutes() - startTemp.getTimezoneOffset());
  stopTemp.setMinutes(stopTemp.getMinutes() - stopTemp.getTimezoneOffset());

  const [start,setStart] = useState(startTemp.toISOString().slice(0,16));
  const [stop,setStop] = useState(stopTemp.toISOString().slice(0,16));

  return (
    <Form onSubmit={e=>{
      e.preventDefault();
      submitEditTimte(moment(start).format('YYYY-MM-DD HH:mm:ss'),moment(stop).format('YYYY-MM-DD HH:mm:ss'))
    }}>
      <Form.Group>
        <Form.Label>Start</Form.Label>
        <Form.Control
          name="timeedit_start"
          type="datetime-local"
          disabled={loading.indexOf('edit_time') >= 0}
          value={start}
          onChange={e=>setStart(e.target.value)}
          max={stop}
        />
      </Form.Group>
      <Form.Group controlId="timeedit_stop">
        <Form.Label>Ende</Form.Label>
        <Form.Control
          name="timeedit_stop"
          type="datetime-local"
          disabled={loading.indexOf('edit_time') >= 0}
          value={stop}
          min={start}
          onChange={e=>setStop(e.target.value)}
        />
      </Form.Group>
      <Button disabled={loading.indexOf('edit_time') >= 0} type="submit">Speichern</Button>{' '}
      <Button disabled={loading.indexOf('edit_time') >= 0} variant={'secondary'} onClick={()=>setEditTime(false)}>Abbrechen</Button>
    </Form>
  );
}


export const Overview = ()=>{
  const [loading,setLoading] = useState(false);
  const [data,setData] = useState(false);

  useEffect(()=>{
    init();
  },[]);

  const init = async ()=>{
    setLoading(true);
    const resp = await api.send('timetracking/overview');
    setData(resp.content);
    setLoading(false);
  }

  if(loading || !data){
    return (
      <div className="text-center">
        <Spinner
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
          variant="light"
        />
      </div>
    )
  }

  return (
    <Card>
      <Card.Header>Timetracking</Card.Header>
      <ListGroup variant="flush">
        {data.map((v,k)=>(
          <ListGroup.Item key={k}>
            <Media>
              <Media.Body>{v.url}</Media.Body>
              <strong>
                {!!v.duration && !!v.price &&(
                  <>{getDuration(v.duration)} + {v.price} €</>
                )}
                {!!v.duration && !v.price &&(
                  <>{getDuration(v.duration)}</>
                )}
                {!v.duration && !!v.price &&(
                  <>{v.price} €</>
                )}
              </strong>
            </Media>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Card>
  );
}

export default CustomersTimetracking;