import React, {useState} from 'react';

import Cookies from 'js-cookie'

import api from 'app/modules/api';

import Header from 'app/elements/Header';
import Icon from 'app/elements/Icon';

import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'

import 'assets/css/login.scss';

const Login = ({checkAuth})=>{
  const [loading,setLoading] = useState(false);
  const [error,setError] = useState(false);
  const handleLogin = async (event)=>{
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    setError(false);
    setLoading(true);
    const l = await api.send('user/login',{
      username: form.login_username.value,
      password: form.login_password.value
    });
    if(l.error === 0){
      Cookies.set('if_token',JSON.stringify(l.content),{ expires: 31, path: '/',sameSite: 'strict' });
      checkAuth();
      return;
    }

    form.login_password.value = '';
    setError(true);
    setLoading(false);
  }
  return (
    <div className="app">
      <Navbar bg="white" variant="light" expand="lg" fixed="top" className={'shadow'}>
        <Navbar.Brand className="ml-auto mr-auto">ideenfrische</Navbar.Brand>
      </Navbar>
      <div className="app__login">
        <Header>
          <Container>
            <div className="app__login__inner">
              <h2><Icon name="person_outline"/> Login</h2>
            </div>
          </Container>
        </Header>
        <Container>
          <div className="app__login__inner">
            <Form onSubmit={handleLogin}>
              <Card>
                <Card.Body>

                  <Form.Group controlId="login_username">
                    <Form.Control type="text" placeholder="E-Mail" autoComplete="on" required  disabled={loading} isInvalid={!!error} />
                  </Form.Group>
                  <Form.Group controlId="login_password">
                    <Form.Control type="password" placeholder="Passwort" autoComplete="on" required  disabled={loading} isInvalid={!!error} />
                    <Form.Control.Feedback type="invalid">
                      Die eingegeben Nutzerdaten sind leider Fehlerhaft.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Card.Body>
                <Card.Footer>
                  <Button variant="primary" type="submit" disabled={loading}>
                    {loading&&(
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    {' '}Anmelden
                  </Button>
                </Card.Footer>
              </Card>
            </Form>
          </div>
        </Container>
      </div>
    </div>
  )
}
export default Login;