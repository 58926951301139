import React,{useEffect,useState} from 'react';
import moment from 'moment';

import api from 'app/modules/api';

import Icon from 'app/elements/Icon';
import Header from 'app/elements/Header';

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Listgroup from 'react-bootstrap/Listgroup'
import Media from 'react-bootstrap/Media'
import Buttongroup from 'react-bootstrap/Buttongroup'

import "assets/css/react-widgets.scss";

moment.locale('de');

const Vacation = ()=>{
  const [loading,setLoading] = useState(false);
  const [year,setYear] = useState(new Date().getFullYear());
  const [admin,setAdmin] = useState(false);

  const [start,setStart] = useState('');
  const [stop,setStop] = useState('');
  const [days,setDays] = useState(0);

  const getOptions = ()=>{
    const last = new Date().getFullYear() + 1;
    let options = [];
    for(let i=2017;i<=last;i++){
      options.push((
        <option value={i} key={i}>{i}</option>
      ))
    }
    return options;
  }

  const getBusinessDatesCount = (startDate, endDate)=>{
      var count = 0;
      var curDate = startDate
      while (curDate <= endDate) {
          var dayOfWeek = curDate.getDay();
          if(!((dayOfWeek === 6) || (dayOfWeek === 0))){
            count++;
          }
          curDate.setDate(curDate.getDate() + 1);
      }
      return count;
  }

  const addNew = async e => {
    e.preventDefault();
    setLoading(true);

    const resp = await api.send('vacation/add',{start:moment(start).format('YYYY-MM-DD'),stop:moment(stop).format('YYYY-MM-DD'),days})
    if(resp.error === 0){
      setStart('');
      setStop('');
      setDays(0);

    }

    setLoading(false);
  }

  useEffect(()=>{
    if(start && stop){
      setDays(getBusinessDatesCount(moment(start).startOf('day').toDate(),moment(stop).startOf('day').toDate()));
    }else{
      setDays(0);
    }
  },[start,stop]);

  const getAdmin = async ()=>{
    const a = await api.send('user/admin');
    setAdmin(a.content);
  }

  useEffect(()=>{
    getAdmin();
  },[]);

  return (
    <>
      <Header>
        <Container>
          <h2><Icon name="weekend"/> Urlaub</h2>
        </Container>
      </Header>
      <Container>
        <Row>
          <Col xl={5}>
            <div className={'mb-3'}>
              <Form.Control as="select" className={'mb-3'} value={year} onChange={e=>{setYear(e.target.value)}}>
                {getOptions()}
              </Form.Control>
              {!admin && (
                <Status year={year}/>
              )}
            </div>
            {!admin && (
              <Form onSubmit={addNew}>
                <Card className={'mb-3 mb-xl-0'}>
                  <Card.Header>Urlaub beantragen</Card.Header>
                  <Card.Body>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>Von</Form.Label>
                        <Form.Control
                          name="start_date"
                          type="date"
                          min={year+'-01-01'}
                          max={year+'-12-31'}
                          value={start}
                          onChange={e=>setStart(e.target.value)}
                          disabled={loading}
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Bis</Form.Label>
                        <Form.Control
                          name="start_date"
                          type="date"
                          min={start?start:(year+'-01-01')}
                          max={year+'-12-31'}
                          value={stop}
                          onChange={e=>setStop(e.target.value)}
                          disabled={loading}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Group controlId="days">
                     <Form.Label>Anzahl Tage</Form.Label>
                     <Form.Control required type="number" min="1" value={days} onChange={(e)=>setDays(e.target.value)} disabled={loading}/>
                   </Form.Group>
                  </Card.Body>
                  <Card.Footer>
                    <Button variant="primary" type="submit" disabled={loading}>
                      Speichern
                    </Button>
                  </Card.Footer>
                </Card>
              </Form>
            )}
          </Col>
          <Col>
            <List year={year} admin={admin}/>
          </Col>
        </Row>
      </Container>
    </>
  )
}

const List = ({year=false,admin=false})=>{
  if(!year){
    year = new Date().getFullYear();
  }

  const [loading,setLoading] = useState(false);
  const [items,setItems] = useState([]);


  useEffect(()=>{
    loadList();
  },[year]);

  const loadList = async ()=>{
    setLoading(true);
    const resp = await api.send('vacation/list',{year});
    setItems(resp.content);
    setLoading(false);
  }

  if(loading){
    return (
      <div className="text-center">
        <Spinner
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
          variant="light"
        />
      </div>
    )
  }
  if(!items.length){
    return (null)
  }

  return (
    <Card>
      <Listgroup>
        {items.map((v,k)=>(
          <ListItem v={v} admin={admin} loadList={loadList} key={'vacation_'+v.id}></ListItem>
        ))}
      </Listgroup>
    </Card>
  )
}

const ListItem = ({v,admin,loadList})=>{
  const [load,setLoad] = useState(false);
  const confirm = async ()=>{
    setLoad('c');
    await api.send('vacation/confirm',{id:v.id});
    setLoad(false);
    loadList();
  }
  const del = async ()=>{
    setLoad('d');
    await api.send('vacation/remove',{id:v.id});
    setLoad(false);
    loadList();
  }
  const formatDate = (date)=>{
    return moment(date).format('L');
  }
  return (
    <Listgroup.Item>
      <Media>
        <Media.Body className={'align-middle'}>
          {!!admin&&v.user_name+': '}
          {formatDate(v.start)} - {formatDate(v.stop)} ({v.days} {v.days===1?'Tag':'Tage'})
        </Media.Body>
        <Buttongroup className={'align-middle'}>
          {!!admin&&(<Button variant={'secondary'} size="sm" disabled={!!v.confirmed} onClick={confirm}>
            {load==='c'?(
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ):(
              <Icon name={'done'}/>
            )}
          </Button>)}
          <Button variant={'secondary'} size="sm" disabled={!!v.confirmed} onClick={del}>
            {load==='d'?(
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ):(
              <Icon name={'delete'}/>
            )}
          </Button>
        </Buttongroup>
      </Media>
    </Listgroup.Item>
  )
}

export const Status = ({year=false,className=''})=>{
  if(!year){
    year = new Date().getFullYear();
  }

  const [loading,setLoading] = useState(false);
  const [status,setStatus] = useState(false);

  useEffect(()=>{
    loadStatus();
  },[year]);

  const loadStatus = async ()=>{
    setLoading(true);
    const resp = await api.send('vacation/config',{year});
    setStatus(resp.content);
    setLoading(false);
  }

  if(loading || !status){
    return (
      <div className="text-center">
        <Spinner
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
          variant="light"
        />
      </div>
    )
  }

  return (
    <Card className={className}>
      <Card.Header>Urlaub - {year}</Card.Header>
      {status.length===1&&(
        <Listgroup variant="flush">
          <Listgroup.Item>
            <Media>
              <Media.Body>Tage gesamt</Media.Body>
              <strong>{status[0].vacation_days}</strong>
            </Media>
          </Listgroup.Item>
          <Listgroup.Item>
            <Media>
              <Media.Body>Tage genutzt</Media.Body>
              <strong>{status[0].days_used}</strong>
            </Media>
          </Listgroup.Item>
          <Listgroup.Item>
            <Media>
              <Media.Body>Tage übrig</Media.Body>
              <strong>{status[0].vacation_days - status[0].days_used}</strong>
            </Media>
          </Listgroup.Item>
        </Listgroup>
      )}
      {status.length!==1&&(
        <Listgroup variant="flush">
          {status.map((v,k)=>(
            <Listgroup.Item key={k}>
              <Media>
                <Media.Body>{v.user_name}</Media.Body>
                <strong>{v.days_used}/{v.vacation_days}</strong>
              </Media>
            </Listgroup.Item>
          ))}
        </Listgroup>
      )}
    </Card>
  )
}

export default Vacation;