import React,{useEffect,useState} from 'react';

import api from 'app/modules/api'

import moment from 'moment';
import moment_de from 'moment/locale/de';

import Header from 'app/elements/Header'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'

import Icon from 'app/elements/Icon'
import Pagination from 'app/elements/Pagination'

import {
  useLocation
} from "react-router-dom";

const Password = ()=>{
  let location = useLocation();

  const limit = 20;
  const [loading,setLoading] = useState([]);
  const [data,setData] = useState([]);
  const [link,setLink] = useState(false);
  const [error,setError] = useState(false);
  const [page,setPage] = useState(1);
  const [deleteModal,setDeleteModal] = useState(false);

  const closeDeleteModal = ()=>{
    setDeleteModal(false);
  }

  const addNew = async e=>{
    e.preventDefault();
    const target = e.target
    load('new_password');
    setError(false);
    setLink(false);
    const data = await api.send('passwords/add',{content:target.content.value,description:target.description.value})
    if(data.error === 0){
      target.content.value = '';
      target.description.value = '';
      setLink('https://password.ideenfrische.de/'+data.content.hash+'/'+data.content.key);
      loadData(1);
    }else{
      setError(true);
    }
    load('new_password',false);
  }

  const deletePassword = async (id)=>{
    setError(false);
    setLink(false);
    closeDeleteModal();
    await api.send('passwords/delete',{id});
    loadData(page);
  }

  const load = (indicator,status=true)=>{
    if(status){
      setLoading(l=>{
        l = [...l];
        l.push(indicator)
        return l;
      });
    }else{
      setLoading(lo=>{
        let l = [...lo];
        l = l.filter(i=>i !== indicator);
        return l;
      });
    }
  }

  const loadData = async (page=1)=>{
    load('data');
    setPage(page);
    const data = await api.send('passwords/list',{limit:limit,offset:limit*(page-1)})
    setData(data.content);
    load('data',false);
  }

  useEffect(()=>{
    moment.locale('de',moment_de);
    loadData();
  },[]);

  const getContent = ()=>{
    let content = location.state?.content||[];
    let text = "";

    content.forEach(v=>{
      text += v.label+": "+v.value+"\n";
    });

    return text;
  }

  return (
    <>
      <Header>
        <Container>
          <h2><Icon name="verified_user"/> Passwörter versenden</h2>
        </Container>
      </Header>
      <Container>
        {!!link&&(
          <Alert variant={'dark'}>
           Die Informationen sind nun unter <a href={link} target="_blank" rel="noopener noreferrer">{link}</a> abrufbar.
         </Alert>
        )}
        {!!error&&(
          <Alert variant={'danger'}>
           Es ist ein Fehler beim speichern aufgetreten.
         </Alert>
        )}
        <Row>
          <Col xl={5}>
            <Form onSubmit={addNew}>
              <Card className={'mb-3 mb-xl-0'}>
                <Card.Header>Neues Passwort</Card.Header>
                <Card.Body>

                  <Form.Group controlId="description">
                    <Form.Label>Beschreibung</Form.Label>
                    <Form.Control type="text" required disabled={loading.indexOf('new_password') >= 0} defaultValue={location.state?.title||''} />
                  </Form.Group>
                  <Form.Group controlId="content">
                    <Form.Label>Inhalt</Form.Label>
                    <Form.Control type="text" as="textarea" required disabled={loading.indexOf('new_password') >= 0} defaultValue={getContent()} />
                    <Form.Text className="text-muted">
                      Der gesamte Inhalt wird verschlüsselt gespeichert und lässt sich nur ein mal über den generierten Link anzeigen.
                    </Form.Text>
                  </Form.Group>
                </Card.Body>
                <Card.Footer>
                  <Button variant="primary" type="submit" disabled={loading.indexOf('new_password') >= 0}>
                    Speichern
                  </Button>
                </Card.Footer>
              </Card>
            </Form>
          </Col>
          <Col>
            {loading.indexOf('data') >= 0 && (
              <div className='text-center'>
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  variant="light"
                />
              </div>
            )}
            {loading.indexOf('data') < 0 && !data.count && (
              <Alert variant={'info'}>
               Du hast bisher keine Passwörter angelegt.
             </Alert>
            )}
            {loading.indexOf('data') < 0 && !!data.count && (
              <Card>
                <Card.Body>
                  <Table striped bordered responsive>
                    <thead>
                      <tr>
                        <th>Datum</th>
                        <th>Beschreibung</th>
                        <th colSpan="2"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!data.data && data.data.map((v,k)=>{
                        let date = moment(v.date);
                        return (
                          <tr>
                            <td className={'align-middle'}>{date.format('lll')}</td>
                            <td className={'align-middle'}>{v.description}</td>
                            <td className={'text-center align-middle'}><Icon name={v.read?'visibility':'visibility_off'}/></td>
                            <td className={'text-center align-middle'}><Button size="sm" variant={'danger'} onClick={()=>{setDeleteModal(v.id)}}><Icon name={'delete'}/></Button></td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                  <Pagination
                    items={data.count}
                    limit={limit}
                    page={page}
                    changePage={(p)=>loadData(p)}
                  />
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
      <Modal show={!!deleteModal} onHide={closeDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Daten löschen?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Die Daten können nicht wiederhergestellt werden.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteModal}>
            Abbrechen
          </Button>
          <Button variant="danger" onClick={()=>deletePassword(deleteModal)}>
            Löschen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Password;