import React from 'react';

import 'assets/css/elements/header.scss';

const Header = ({children,small})=>{
  return (
    <div className={'header'+(small?' header--small':'')}>
      {!!children&&(
        <div className="header__inner">
          {children}
        </div>
      )}
    </div>
  )
}

export default Header;