import React,{useState,useEffect} from 'react';
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Cookies from 'js-cookie'

import 'assets/css/app.scss';
import 'assets/css/nav.scss';

import api from 'app/modules/api';
import notifications from 'app/modules/notifications';
import {Unread} from 'app/elements/Notifications';

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import DropdownButton from 'react-bootstrap/DropdownButton'

import { LinkContainer } from 'react-router-bootstrap'

import NoMatch from 'app/wrapper/NoMatch';

import Dashboard from 'app/screens/Dashboard';
import Password from 'app/screens/Password';

import Timelog from 'app/screens/Timelog';
import Vacation from 'app/screens/Vacation';
import Bookmarklet from 'app/screens/Bookmarklet';
import Trashmail from 'app/screens/Trashmail';

import Customers from 'app/wrapper/Customers';

import Icon from 'app/elements/Icon'

const Wrapper = ({logout,children})=>{
  const [sidebar,setSidebar] = useState(false);

  const [notification,setNotification] = useState(notifications.getData());

  useEffect(()=>{
    let li = notifications.addListener(setNotification);
    return ()=>{
      notifications.removeListener(li);
    }
  },[]);

  let username = '';
  if(Cookies.get('if_token')){
    username = JSON.parse(Cookies.get('if_token'))[0];
  }

  return (
    <div className="app">
      <Navbar bg="white" variant="light" expand="lg" fixed="top" className={'shadow'}>
        <Navbar.Brand>ideenfrische</Navbar.Brand>

        <div className="ml-auto">
          <Timetracking/>
          <DropdownButton as={ButtonGroup} variant={notification.length?'pink':'light'} className="mr-3 mr-lg-0 align-middle" title={(<Icon name="inbox"/>)} drop={'left'}>
            <Unread/>
          </DropdownButton>
        </div>
        <Navbar.Toggle onClick={()=>{setSidebar(!sidebar)}} />
      </Navbar>
      <div className="app__content">
        <div className={'app__sidebar'+(sidebar?' app__sidebar--active':'')}>
          <div className="app_sidebar__main">
            <Nav className="flex-column">
              <LinkContainer to="/app/dashboard"><Nav.Link onClick={()=>setSidebar(false)}>Dashboard</Nav.Link></LinkContainer>

              <div className="nav-headline">Intern</div>
              <LinkContainer to="/app/timelog"><Nav.Link onClick={()=>setSidebar(false)}><Icon name="access_time"/> Timelog</Nav.Link></LinkContainer>
              <LinkContainer to="/app/vacation"><Nav.Link onClick={()=>setSidebar(false)}><Icon name="weekend"/> Urlaub</Nav.Link></LinkContainer>

              <div className="nav-headline">Kunden</div>
              <LinkContainer to="/app/customers/credentials"><Nav.Link onClick={()=>setSidebar(false)}><Icon name="vpn_key"/> Zugangsdaten</Nav.Link></LinkContainer>
              <LinkContainer to="/app/customers/timetracking"><Nav.Link onClick={()=>setSidebar(false)}><Icon name="access_alarm"/> Timetracking</Nav.Link></LinkContainer>
              <LinkContainer to="/app/customers/database"><Nav.Link onClick={()=>setSidebar(false)}><Icon name="storage"/> DB-Backup</Nav.Link></LinkContainer>
              <LinkContainer to="/app/customers/info"><Nav.Link onClick={()=>setSidebar(false)}><Icon name="face"/> Kunden verwalten</Nav.Link></LinkContainer>

              <div className="nav-headline">Tools</div>
              <LinkContainer to="/app/password"><Nav.Link onClick={()=>setSidebar(false)}><Icon name="verified_user"/> Passwörter versenden</Nav.Link></LinkContainer>
              <LinkContainer to="/app/bookmarklet"><Nav.Link onClick={()=>setSidebar(false)}><Icon name="turned_in"/> Lesezeichen</Nav.Link></LinkContainer>
              <LinkContainer to="/app/trashmail"><Nav.Link onClick={()=>setSidebar(false)}><Icon name="mail"/> Trashmail</Nav.Link></LinkContainer>

              <div className="nav-headline">Extern</div>
              <Nav.Link href="https://git.ideenfrische.de/ideenfrische" target="_blank" rel="noopener noreferrer"><Icon name="folder"/> Gitlab</Nav.Link>
              <Nav.Link href="https://git.ideenfrische.de/resource/wiki/wikis/home" target="_blank" rel="noopener noreferrer"><Icon name="help"/> Wiki</Nav.Link>
              <Nav.Link href="https://basecamp.com/1798908/" target="_blank" rel="noopener noreferrer"><Icon name="list"/> Basecamp</Nav.Link>
            </Nav>
          </div>
          <div className="app__sidebar__footer small text-center">
            <div>Eingeloggt als: {username}</div>
            <div className={'mt-1'}>
              <Button size="sm" variant="danger" onClick={logout}>Logout</Button>
            </div>
          </div>
        </div>
        <div className="app__inner">
          {children}
        </div>
      </div>
    </div>
  )
}

const App = ({checkAuth})=>{
  const logout = ()=>{
    api.send('user/logout');
    Cookies.remove('if_token');
    checkAuth();
  }

  return (
    <Switch>
      <Route exact path="/app/dashboard">
        <Wrapper logout={logout}><Dashboard/></Wrapper>
      </Route>
      <Route exact path="/app/password">
        <Wrapper logout={logout}><Password/></Wrapper>
      </Route>

      <Route exact path="/app/timelog">
        <Wrapper logout={logout}><Timelog/></Wrapper>
      </Route>
      <Route exact path="/app/vacation">
        <Wrapper logout={logout}><Vacation/></Wrapper>
      </Route>
      <Route exact path="/app/bookmarklet">
        <Wrapper logout={logout}><Bookmarklet/></Wrapper>
      </Route>
      <Route exact path="/app/trashmail">
        <Wrapper logout={logout}><Trashmail/></Wrapper>
      </Route>

      <Route path="/app/customers">
        <Wrapper logout={logout}><Customers/></Wrapper>
      </Route>

      <Route exact path="/app">
        <Redirect to="/app/dashboard"/>
      </Route>
      <Route path="*">
        <NoMatch nowrapper/>
      </Route>
    </Switch>
  )
}

const Timetracking = ()=>{
  const [data,setData] = useState(false);

  useEffect(()=>{
    loadTimetracking();
    const interval = setInterval(()=>{
      loadTimetracking();
    },5000);
    return ()=>{
      clearInterval(interval);
    }
  },[]);

  const stop = async ()=>{
    await api.send('timetracking/stop',{item_id:data.item_id});
    loadTimetracking()
  }

  const loadTimetracking = async ()=>{
    const timetracking = await api.send('timetracking/status');
    setData(timetracking.content);
  }
  if(!data || !data.tracking){
    return (null);
  }

  return (
    <span className="mr-3">
      <Button variant={'pink'} onClick={stop}><Icon name="pause"/>{data.item_title} stoppen</Button>
    </span>
  )
}

export default App;