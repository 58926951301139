import React,{useEffect,useState} from 'react';
import ReactTagInput from "@pathofdev/react-tag-input";

import api from 'app/modules/api';

import Icon from 'app/elements/Icon';
import Header from 'app/elements/Header';
import Pagination from 'app/elements/Pagination';

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Media from 'react-bootstrap/Media'
import Badge from 'react-bootstrap/Badge'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Modal from 'react-bootstrap/Modal'

import "@pathofdev/react-tag-input/src/styles/index.scss";

const Bookmarklet = ()=>{
  const [edit,setEdit] = useState(false);
  const [filter,setFilter] = useState({tag:'',text:''});
  const [reload,setReload] = useState(false);

  useEffect(()=>{
    if(reload){setReload(false);}
  },[reload]);

  return (
    <>
      <Header>
        <Container>
          <Media>
            <Media.Body><h2><Icon name="turned_in"/> Lesezeichen</h2></Media.Body>
            <Button variant={'success'} onClick={()=>{setEdit('new')}} disabled={!!edit}><Icon name="add"/></Button>
          </Media>
        </Container>
      </Header>
      <Container>
        {!!edit && (
          <Edit edit={edit} setEdit={setEdit} reload={()=>setReload(true)}/>
        )}
        {!reload&&(
          <>
            <Filter setFilter={setFilter}/>
            <List filter={filter} edit={edit} setEdit={setEdit}/>
          </>
        )}
      </Container>
    </>
  )
}

const Edit = ({edit,setEdit,reload})=>{
  const [loading,setLoading] = useState(false);
  const [showInfo,setShowInfo] = useState(edit!=='new');
  const [defData,setDefdata] = useState(edit!=='new'?edit:{});
  const [tags,setTags] = useState(edit!=='new'?edit.tags:[]);

  const submit = async e=>{
    e.preventDefault();
    setLoading(true);

    const url = e.target.url.value;
    const name = e.target?.title?.value;
    const description = e.target?.description?.value;

    if(edit==='new' && !showInfo){
      const check = await api.send('bookmarklet/check',{url});
      setShowInfo(true);
      setDefdata(check.content);
    }else if(edit==='new'){
      await api.send('bookmarklet/add',{url,name,description,tags:JSON.stringify(tags)});
      setEdit(false);
      reload();
    }else{
      await api.send('bookmarklet/edit',{id:edit.id,url,name,description,tags:JSON.stringify(tags)});
      setEdit(false);
      reload();
    }
    setLoading(false);
  }

  return (
    <Form className="mb-3" onSubmit={submit}>
      <Card>
        <Card.Body>
          <Form.Group controlId="url">
            <Form.Label>URL</Form.Label>
            <Form.Control type="url" disabled={loading} autoComplete="off" defaultValue={defData?.url}/>
          </Form.Group>
          {!!showInfo&&(
            <>
              <Form.Group controlId="title">
                <Form.Label>Titel</Form.Label>
                <Form.Control type="text" disabled={loading} defaultValue={defData.title} autoComplete="off"/>
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Label>Beschreibung</Form.Label>
                <Form.Control type="textarea" disabled={loading} defaultValue={defData.description} autoComplete="off"/>
              </Form.Group>
              <Form.Group>
                <Form.Label>Tags</Form.Label>
                <ReactTagInput
                  tags={tags}
                  onChange={setTags}
                  placeholder="Tags eingeben"
                />
              </Form.Group>
            </>
          )}
        </Card.Body>
        <Card.Footer>
          <Button disabled={loading} type="submit">Speichern</Button>{' '}<Button disabled={loading} variant="secondary" onClick={()=>setEdit(false)}>Abbrechen</Button>
        </Card.Footer>
      </Card>
    </Form>
  )
}

const Filter = ({setFilter})=>{
  const [tags,setTags] = useState([]);

  useEffect(()=>{
    init();
  },[]);

  const init = async ()=>{
    const resp = await api.send('bookmarklet/list_tags');
    setTags(resp.content);
  }

  const changetext = e=>{
    const text = e.target.value;
    setFilter(f=>{
      let filter = {...f};
      filter.text = text;
      return filter;
    })
  }
  const changetag = e=>{
    const text = e.target.value;
    setFilter(f=>{
      let filter = {...f};
      filter.tag = text;
      return filter;
    })
  }

  return (
    <Form className="mb-3">
      <Row>
        <Col>
          <Form.Control placeholder="Suchen…" type="search" onChange={changetext} />
        </Col>
        <Col sm="4">
          <Form.Control as="select"  onChange={changetag}>
            <option value="">alle Tags</option>
            {!!tags.length && tags.map((v,k)=>(
              <option value={v.id} key={"tag_"+v.id} >{v.tag} ({v.count})</option>
            ))}
          </Form.Control>
        </Col>
      </Row>
    </Form>
  )
}

let timeout;
const List = ({filter,setEdit,edit})=>{
  const limit = 20;
  const [count,setCount] = useState(0);
  const [page,setPage] = useState(1);
  const [loading,setLoading] = useState(false);
  const [items,setItems] = useState([]);
  const [del,setDel] = useState(false);

  const deleteItem = async ()=>{
    setDel(false);
    setLoading(true);
    await api.send('bookmarklet/remove',{id:del});
    reload();
  }

  useEffect(()=>{
    setLoading(true);
    clearTimeout(timeout);
    setPage(1);
    timeout = setTimeout(reload,200);
  },[filter]);
  useEffect(()=>{
    setLoading(true);
    reload();
  },[page]);

  const reload = async ()=>{
    const resp = await api.send('bookmarklet/list',{fragment:filter.text,tag:filter.tag,limit,offset: (page-1)*limit});
    setItems(resp.content.items);
    setCount(resp.content.count);
    setLoading(false);
  }

  if(loading){
    return (
      <div className="text-center">
        <Spinner
           as="span"
           animation="border"
           role="status"
           aria-hidden="true"
           variant="light"
         />
       </div>
    );
  }

  return (
    <>
      {items.map((v,k)=>(
        <Card key={'item_'+v.id} className="mb-3">
          <Card.Header>
            <Media>
              <Media.Body>{v.title}</Media.Body>
              <ButtonGroup>
                <Button disabled={!!edit} onClick={()=>{setEdit(v)}} variant="secondary" size="sm"><Icon name="edit"/></Button>
                <Button onClick={()=>{setDel(v.id)}} variant="secondary" size="sm"><Icon name="delete"/></Button>
              </ButtonGroup>
            </Media>
          </Card.Header>
          <Card.Body>
            <p>{v.description}</p>
            <Media>
              <Media.Body><small>{v.url}</small></Media.Body>
              {v.tags.map(t=>(
                <Badge variant="info" key={'tag_'+t} className="ml-1">{t}</Badge>
              ))}
            </Media>
          </Card.Body>
          <Card.Footer>
            <Button onClick={()=>window.open(v.url)}>Öffnen</Button>
          </Card.Footer>
        </Card>
      ))}
      <Pagination
        items={count}
        limit={limit}
        page={page}
        changePage={(p)=>setPage(p)}
      />
      <Modal show={!!del} onHide={()=>{setDel(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>Daten löschen?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Die Daten können nicht wiederhergestellt werden.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{setDel(false)}}>
            Abbrechen
          </Button>
          <Button variant="danger" onClick={deleteItem}>
            Löschen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Bookmarklet;