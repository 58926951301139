import React from 'react';

import 'assets/css/elements/icon.scss';

const Icon = ({name,className='',size=1})=>{
  return (
    <i className={'icon '+className} style={{fontSize:size+'em'}}>{name}</i>
  )
}

export default Icon;