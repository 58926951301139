import React,{useEffect,useState} from 'react';

import notifications from 'app/modules/notifications';

import Dropdown from 'react-bootstrap/Dropdown'

export const Unread = ()=>{

  const [list,setList] = useState(notifications.getData());

  useEffect(()=>{
    let li = notifications.addListener(setList);
    return ()=>{
      notifications.removeListener(li);
    }
  },[]);

  if(!list.length){
    return (<div className="px-3"><small>Keine ungelesenen Benachrichtigungen vorhanden.</small></div>);
  }

  return (
    <>
      {list.map((v,k)=>(
        <Dropdown.Item onClick={()=>notifications.onClick(v)} key={v.id}>{notifications.getText(v)}</Dropdown.Item>
      ))}
    </>
  )
}
