const api = ()=>{
  let auth = false;
  let key = false;

  const requestArgs = (a,f)=>{
    let formData = new FormData();

    Object.keys(f).forEach(k=>{
      formData.append(k, f[k], f[k].name);
    })
    Object.keys(a).forEach(k=>{
      formData.append(k, a[k]);
    })

    return formData;
  }

  const setAuth = (a)=>{
    auth = a;
  }

  const send = async (route,args={},files={},download=false)=>{
    if(key === false){
      await init();
    }
    if(auth){
      args.username = auth[0];
      args.token = auth[1];
    }
    if(key){
      args.vpnkey = key;
    }
    return new Promise((resolve, reject)=>{
      var xhr = new XMLHttpRequest();
      xhr.open("POST", 'https://api.app.ideenfrische.de/'+route, true);
      xhr.onreadystatechange = function() {
        if (this.readyState === XMLHttpRequest.DONE) {
          if(download){
            resolve(this);
          }else{
            resolve(JSON.parse(this.response));
          }
        }
      }
      xhr.send(requestArgs(args,files));
    });
  }

  const download = async (path,data,name)=>{
    const file = await send(path,data,{},true);

    var element = document.createElement('a');
    element.setAttribute('href', 'data:'+file.getResponseHeader('content-type')+';charset=utf-8,' + encodeURIComponent(file.response));
    element.setAttribute('download', name);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    return true;
  }

  const init = async ()=>{
    return new Promise((resolve, reject)=>{
      // var xhr = new XMLHttpRequest();
      // xhr.open("GET", 'https://auth.vpn.ideenfrische.de:112/auth.php', true);
      // xhr.timeout = 2000;
      // xhr.onreadystatechange = function() {
      //   if (this.readyState === XMLHttpRequest.DONE) {
      //     key = '';
      //     if(this.status === 200){
      //       key = JSON.parse(this.response).key;
      //     }else{
            key = '';
          // }
          resolve();
      //   }
      // }
      // xhr.send();
    });
  }

  const isAuth = ()=>{
    return !!auth;
  }

  return {download,send,setAuth,isAuth}
}

export default api();
