import React,{useEffect,useState} from 'react';
import moment from 'moment';

import api from 'app/modules/api';

import Icon from 'app/elements/Icon';
import Header from 'app/elements/Header';

import Container from 'react-bootstrap/Container'
import Media from 'react-bootstrap/Media'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'
import Accordion from 'react-bootstrap/Accordion'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import ListGroup from 'react-bootstrap/ListGroup'
import Modal from 'react-bootstrap/Modal'

import { TimeInput } from 'react-widgets'
// import momentLocalizer from 'react-widgets-moment';
import "assets/css/react-widgets.scss";

moment.locale('de')
// momentLocalizer();


const CustomersDatabase = ({customer})=>{
  const [edit,setEdit] = useState(false);
  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(false);
  const [deleteId,setDeleteId] = useState(false);

  useEffect(()=>{
    loadData();
  },[customer]);

  const loadData = async ()=>{
    setLoading(true);
    const resp = await api.send('backup/list',{customer_id:customer.id});
    setData(resp.content);
    setLoading(false);
  }

  const hideDelete = ()=>{
    setDeleteId(false);
  }

  const deleteBackup = async ()=>{
    const backup_id = deleteId;
    hideDelete();
    setLoading(true);
    await api.send('backup/delete',{backup_id});
    loadData();
  }


  useEffect(()=>{
    setEdit(false)
  },[customer]);

  return (
    <>
      <Header>
        <Container>
          <Media>
            <Media.Body><h2><Icon name="storage"/> DB-Backup</h2></Media.Body>
            <Button variant={'success'} onClick={()=>{setEdit('new')}} disabled={!!edit}><Icon name="add"/></Button>
          </Media>
        </Container>
      </Header>
      <Container>
        {edit!==false&&(
          <EditBackup edit={edit} setEdit={setEdit} customer={customer} loadData={loadData} data={data}/>
        )}
        <ListBackup customer={customer} loading={loading} data={data} loadData={loadData} setEdit={setEdit} del={setDeleteId}/>
      </Container>
      <Modal show={deleteId!==false} onHide={hideDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Daten löschen?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Die Daten können nicht wiederhergestellt werden.</Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={hideDelete}>
          Abbrechen
        </Button>
        <Button variant="danger" onClick={deleteBackup}>
          Löschen
        </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const ListBackup = ({customer,loading,data,loadData,setEdit,del})=>{
  if(loading){
    return (
      <div className='text-center'>
        <Spinner
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
          variant="light"
        />
      </div>
    );
  }

  if(!data.length){
    return (
      <Alert variant={'info'}>Es sind keine Backup-Aufräge angelegt.</Alert>
    )
  }

  return (
    <Accordion>
      {data.map(d=>(
        <BackupItem key={d.id} d={d} loadData={loadData} setEdit={setEdit} del={del}/>
      ))}
    </Accordion>
  )
}

const BackupItem = ({d,loadData,setEdit,del})=>{
  const [syncLoading,setSyncLoading] = useState(false);
  const [downloadLoading,setDownloadLoading] = useState(false);

  const download = async (id)=>{
    setDownloadLoading(true);
    await api.download('backup/file',{backup_id:id},'db_backup.php');
    setDownloadLoading(false);
  }
  const sync = async (id)=>{
    setSyncLoading(true);
    await api.send('backup/check',{backup_id:id});
    loadData();
    setSyncLoading(false);
  }
  let status = ['danger','Bisher wurde kein Check durchgeführt.'];
  if(d.check){
    if(!d.check.file_exists){
      status = ['danger','Die Datei konnte am Zielort nicht gefunden werden.'];
    }else if(!d.check.file_rights){
      status = ['danger','Die Datei am Zielort hat nicht die Rechte um Dateien anzulegen.'];
    }else if(!d.check.php_version){
      status = ['danger','Die PHP-Version am Zielserver ist leider veraltet. Es wird PHP 7.0 oder höher benötigt.'];
    }else if(!d.check.db_connection){
      status = ['danger','Es konnte keine Verbindung zur Datenbank aufgebaut werden. Prüfen Sie die Zugangsdaten.'];
    }else{
      status = ['dark','Der Backup-Prozess ist fehlerfrei.'];
    }
  }

  return (
    <Card>
      <Card.Header>
        <Media>
          <Media.Body>{d.title}</Media.Body>
          <ButtonGroup>
            <OverlayTrigger
              key="status"
              placement="left"
              overlay={
                <Tooltip id={"tooltip-"+d.id}>
                  {status[1]}
                </Tooltip>
              }
            >
              <Button variant={status[0]} size="sm"><Icon name={'info'}/></Button>
            </OverlayTrigger>
            <Button variant="secondary" eventKey={'edit'+d.id} size="sm" onClick={()=>setEdit(d.id)}>
              <Icon name={'edit'} />
            </Button>
            <Accordion.Toggle as={Button} variant="secondary" eventKey={'downloads'+d.id} size="sm" disabled={!Object.keys(d.files).length}>
              <Icon name={'cloud_download'} />
            </Accordion.Toggle>
            <Button variant="secondary" size="sm" onClick={()=>download(d.id)}>
              {downloadLoading?(
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ):(
                <Icon name={'insert_drive_file'}/>
              )}
            </Button>
            <Button variant="secondary" size="sm" onClick={()=>sync(d.id)}>
              {syncLoading?(
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ):(
                <Icon name={'sync'}/>
              )}
            </Button>
            <Button variant="secondary" size="sm" onClick={()=>del(d.id)}>
              <Icon name={'delete'}/>
            </Button>
          </ButtonGroup>
        </Media>
      </Card.Header>
      <Accordion.Collapse eventKey={'downloads'+d.id}>
        <Downloads d={d}/>
      </Accordion.Collapse>
    </Card>
  )
}

const Downloads = ({d})=>{
  let days = Object.keys(d.files);

  const [loading,setLoading] = useState(false);

  const download = async (date,database)=>{
    setLoading([date,database]);
    await api.download('backup/download',{backup_id:d.id,date,database},database+'.sql');
    setLoading(false);
  }

  if(!days.length){
    return (null);
  }
  return (
    <ListGroup variant="flush">
      {days.map(v=>(
        <ListGroup.Item key={'day_'+v}>
          <div className="mb-2">{moment(v).format('L')}</div>
          {d.files[v].map((v1)=>(
            <>
              <Button className="mr-1 mb-1" key={'button'+v+v1} variant="secondary" size="sm" onClick={()=>download(v,v1)}>
              {(loading && loading[0] === v && loading[1] === v1)? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ):v1}
              </Button>
            </>
          ))}
        </ListGroup.Item>
      ))}
    </ListGroup>
  )
}

const EditBackup = ({customer,edit,setEdit,loadData,data})=>{
  let d = {};
  if(edit !== 'new'){
    data.forEach((v,k)=>{
      if(v.id === edit){
        d = v;
      }
    })
  }

  const [loading,setLoading] = useState(false);

  const [file,setFile] = useState(edit!=='new'&&d.backup_file?d.backup_file:'');
  const [title,setTitle] = useState(edit!=='new'&&d.title?d.title:'');
  const [copies,setCopies] = useState(edit!=='new'&&d.copies?d.copies:7);
  const [time,setTime] = useState(edit!=='new'&&d.time?new Date('2020-01-01 '+d.time):new Date('2020-01-01 00:00:00'));
  const [credentials,setCredentials] = useState(edit!=='new'&&d.credentials?d.credentials:{
    db_host: '',
    db_user: '',
    db_pass: '',
    db_port: 3306
  })

  const submit = async e=>{
    e.preventDefault();
    setLoading(true);
    if(edit === 'new'){
      await api.send('backup/add',{
        title,
        customer_id: customer.id,
        credentials: JSON.stringify(credentials),
        time: time.getHours()+':'+time.getMinutes(),
        copies
      });
    }else{
      await api.send('backup/edit',{
        backup_id: edit,
        title,
        customer_id: customer.id,
        credentials: JSON.stringify(credentials),
        time: time.getHours()+':'+time.getMinutes(),
        copies,
        file
      });
    }
    loadData();
    setEdit(false);
  }
  const updateCredentials = (field,value)=>{
    setCredentials(cred=>{
      let c = {...cred}
      c[field] = value;
      return c;
    })
  }

  return (
    <Form onSubmit={submit} className={'mb-3'}>
      <Card>
        <Card.Header>
          {title?title:'Titel'}
        </Card.Header>
        <Card.Body>
          <Form.Group as={Row} controlId={'edit_title'}>
            <Form.Label column xl="2">Titel</Form.Label>
            <Col xl="10">
              <Form.Control required disabled={loading} autoComplete="off" value={title} onChange={e=>setTitle(e.target.value)}/>
            </Col>
          </Form.Group>
          <hr/>
          <Form.Group as={Row} controlId={'edit_copies'}>
            <Form.Label column xl="2">Kopien behalten</Form.Label>
            <Col xl="10">
              <Form.Control type={'number'} min={0} required disabled={loading} autoComplete="off" value={copies} onChange={e=>setCopies(e.target.value)}/>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId={'edit_time'}>
            <Form.Label column xl="2">Uhrzeit</Form.Label>
            <Col xl="10">
              <TimeInput value={time} disabled={loading} onChange={d=>{setTime(d)}}/>
            </Col>
          </Form.Group>
          <hr/>
          <Form.Group as={Row} controlId={'edit_host'}>
            <Form.Label column xl="2">Host</Form.Label>
            <Col xl="10">
              <Form.Control value={credentials.db_host} required disabled={loading} autoComplete="off" onChange={e=>updateCredentials('db_host',e.target.value)}/>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId={'edit_user'}>
            <Form.Label column xl="2">Nutzername</Form.Label>
            <Col xl="10">
              <Form.Control value={credentials.db_user} required disabled={loading} autoComplete="off" onChange={e=>updateCredentials('db_user',e.target.value)}/>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId={'edit_pass'}>
            <Form.Label column xl="2">Passwort</Form.Label>
            <Col xl="10">
              <Form.Control value={credentials.db_pass} type="password" required disabled={loading} autoComplete="off" onChange={e=>updateCredentials('db_pass',e.target.value)}/>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId={'edit_port'}>
            <Form.Label column xl="2">Port</Form.Label>
            <Col xl="10">
              <Form.Control value={credentials.db_port} type="number" required disabled={loading} autoComplete="off" onChange={e=>updateCredentials('db_port',e.target.value)}/>
            </Col>
          </Form.Group>
          {edit !== 'new' && (
            <>
              <hr/>
              <Form.Group as={Row} controlId={'edit_file'}>
                <Form.Label column xl="2">Zieldatei</Form.Label>
                <Col xl="10">
                  <Form.Control type={'url'} required disabled={loading} autoComplete="off" value={file} onChange={e=>setFile(e.target.value)}/>
                </Col>
              </Form.Group>
            </>
          )}
        </Card.Body>
        <Card.Footer>
          <Button variant="primary" type="submit">
            Speichern
          </Button>
          {' '}
          <Button variant="secondary" onClick={()=>{setEdit(false)}}>
            Abbrechen
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  );
}
export default CustomersDatabase;