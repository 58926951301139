import React,{useState,useEffect} from 'react';
import moment from 'moment';

import Buffer from 'buffer';

import Icon from 'app/elements/Icon'
import Header from 'app/elements/Header';
import api from 'app/modules/api';

import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner'
import Media from 'react-bootstrap/Media'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import 'assets/css/email-iframe.scss'

const Trashmail = ()=>{
  const [loading,setLoading] = useState(false);
  const [sidebar,setSidebar] = useState(true);
  const [emails,setEmails] = useState([]);
  const [active,setactive] = useState(false);
  const [del,setDel] = useState(0);
  const [canLoad,setCanLoad] = useState(true);
  const [credentials,setCredentials] = useState(false);
  console.log(Buffer);

  useEffect(()=>{
    loadEmails(true);
    loadCredentials();
  },[]);

  useEffect(()=>{
    if(active){
      setEmails(email=>{
        let e = [...email];
        e.forEach((m,k)=>{
          if(m.id === active && !m.read){
            e[k].read = 1;
            api.send('trashmail/read',{mail_id:active});
          }
        })

        return e;
      })
      setSidebar(false);
    }
  },[active]);

  const loadCredentials = async ()=>{
    const resp = await api.send('trashmail/credentials');
    setCredentials(resp.content);
  }

  const deleteItem = ()=>{
    const d = del;

    setEmails(em=>{
      em = em.filter(v=>v.id!==d);
      return em;
    });

    api.send('trashmail/delete',{mail_id:d});

    setDel(false);
    setactive(false);
  }
  const closeDeleteModal = ()=>{
    setDel(false);
  }

  const loadEmails = async (del=false)=>{
    const limit = 30;

    setLoading(true);

    if(del){
      setCanLoad(true);
      setEmails([]);
      await api.send('trashmail/sync');
    }

    let resp = await api.send('trashmail/list',{limit:limit,offset:(del?0:emails.length)});
    setEmails(del?resp.content:[...emails,...resp.content]);
    setLoading(false);
    if(limit !== resp.content.length){
      setCanLoad(false);
    }
  }

  const formatNames = (names,short=false)=>{
    let list = [];
    names.forEach(i=>{
      let item = i.mailbox+'@'+i.host;
      if(i.personal){
        if(short){
          item = i.personal
        }else{
          item = i.personal+' <'+item+'>';
        }
      }
      list.push(item);
    });
    return list.join(', ');
  }

  return (
    <div className="customers">
      <div className={"customers__sidebar "+(sidebar?' customers__sidebar--active':'')}>
      <div className="customers__sidebar__toggler" onClick={()=>{setSidebar(!sidebar)}}><Icon name={sidebar?'keyboard_arrow_left':'keyboard_arrow_right'}/></div>
        <div className="customers__sidebar__inner">
          <div className="m-3">
            <Button onClick={()=>loadEmails(true)} disabled={loading} variant="secondary"><Icon name="sync"/> Neu laden</Button>
          </div>
          <ListGroup as="ul" variant="flush">
            {emails.map(email=>(
              <ListGroup.Item as="li" key={'email_'+email.id} className={'customers__item'} onClick={()=>setactive(email.id)} active={email.id === active}>
                <small>
                  <Media>
                    <Media.Body className="text-truncate">{formatNames(email.from,true)}</Media.Body>
                    {moment(email.date).fromNow()}
                  </Media>
                </small>
                <div className="text-truncate">
                  {email.read?(
                    email.subject?email.subject:['[kein Betreff]']
                  ):(
                    <strong>{email.subject?email.subject:['[kein Betreff]']}</strong>
                  )}
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
          {canLoad && !loading &&(
            <Button className="m-3" onClick={()=>{loadEmails(false)}}>mehr</Button>
          )}
          {loading && (
            <div className="text-center mt-3">
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                variant="dark"
              />
            </div>
          )}
        </div>
      </div>
      <div className="customers__content">
        <MailBody active={active} emails={emails} formatNames={formatNames} del={(i)=>setDel(i)} credentials={credentials}/>
      </div>
      <Modal show={!!del} onHide={closeDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Daten löschen?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Die Daten können nicht wiederhergestellt werden.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteModal}>
            Abbrechen
          </Button>
          <Button variant="danger" onClick={deleteItem}>
            Löschen
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

const MailBody = ({active,emails,formatNames,del,credentials})=>{
  const [mode,setMode] = useState('plain');

  useEffect(()=>{
    setMode('plain');
  },[active]);

  if(!emails.length){
    return null;
  }

  if(!active){
    return (
      <Container className="text-center mt-5">Bitte wähle eine E-Mail aus.</Container>
    )
  }
  let email = {};
  emails.forEach(mail=>{
    if(mail.id === active){
      email = mail;
    }
  });

  const nl2br = (str, is_xhtml)=>{
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  }
  if(!email.content_html){
    email.content_html = '';
  }

  let buffer = new Buffer.Buffer(email.content_html);
  return (
    <>
      <Header>
        <Container>
          <h2><Icon name="mail"/> Trashmail</h2>
        </Container>
      </Header>
      <Container>
        <Card className={'mb-3'}>
          <Card.Header>
            {email.subject?email.subject:'[kein Betreff]'}
          </Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <Media>
                <Media.Body>Datum:</Media.Body>
                <strong>{moment(email.date).format('LLL')}</strong>
              </Media>
            </ListGroup.Item>
            <ListGroup.Item>
              <Media>
                <Media.Body>Absender:</Media.Body>
                <strong>{formatNames(email.from)}</strong>
              </Media>
            </ListGroup.Item>
            <ListGroup.Item>
              <Media>
                <Media.Body>Empfänger:</Media.Body>
                <strong>{formatNames(email.to)}</strong>
              </Media>
            </ListGroup.Item>
          </ListGroup>
          <Card.Footer className="text-right">
            <Button size="sm" variant="danger" onClick={()=>del(email.id)}>Löschen</Button>
          </Card.Footer>
        </Card>

        <Card className={'mb-3'}>
          <Card.Header>
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link active={mode==='plain'} onClick={()=>setMode('plain')}>Text</Nav.Link>
              </Nav.Item>
              {!!email.content_html&&(
                <Nav.Item>
                  <Nav.Link active={mode==='html'} onClick={()=>setMode('html')}>HTML</Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item>
                <Nav.Link active={mode==='webmail'} onClick={()=>setMode('webmail')}>Webmail</Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Card.Body>
            {mode==='plain'&&(
              <div dangerouslySetInnerHTML={{ __html: nl2br(email.content_plain) }}></div>
            )}
            {mode==='html'&&!!email.content_html&&(
              <iframe title="HTML Mail Content" className={"email-iframe"} src={"data:text/html;base64,"+(buffer.toString('base64'))}></iframe>
            )}
            {mode==='webmail'&&!!credentials&&(
              <>
                <iframe title="Webmail Login" name="webmail" style={{width:0,height:0,overflow:'hidden',border:'none',margin:0,position:'absolute'}} src="" onLoad={()=>window.open('https://webmail.tm.ideenfrische.de/imp/dynamic.php?page=mailbox#msg:SU5CT1g;'+email.mail_id_real)} />
                <form method="post" action="https://webmail.tm.ideenfrische.de/login.php" target="webmail">
                  <input type="hidden" name="app" value=""/>
                  <input type="hidden" name="login_post" value="0"/>
                  <input type="hidden" name="url" value="https://webmail.tm.ideenfrische.de/imp/dynamic.php?page=mailbox"/>
                  <input type="hidden" name="anchor_string" value={"msg:SU5CT1g;"+email.mail_id_real}/>

                  <input type="hidden" name="horde_user" value={credentials[0]}/>
                  <input type="hidden" name="horde_pass" value={credentials[1]}/>
                  <input type="hidden" name="horde_select_view" value="auto"/>
                  <input type="hidden" name="new_lang" value="de_DE"/>
                  <input type="hidden" name="login_button" value="Log in"/>

                  <Button type="submit" >Webmail öffnen</Button>
                </form>
              </>
            )}
          </Card.Body>
        </Card>
      </Container>
    </>
  )
}
export default Trashmail;