import React,{useState,useEffect} from 'react';

import {
  Switch,
  Route
} from "react-router-dom";

import NoMatch from 'app/wrapper/NoMatch';

import CustomersInfo from 'app/screens/CustomersInfo';
import CustomersNew from 'app/screens/CustomersNew';
import CustomersTimetracking from 'app/screens/CustomersTimetracking';
import CustomersCredentials from 'app/screens/CustomersCredentials';
import CustomersDatabase from 'app/screens/CustomersDatabase';

import { LinkContainer } from 'react-router-bootstrap'

import ListGroup from 'react-bootstrap/ListGroup';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Media from 'react-bootstrap/Media'

import Icon from 'app/elements/Icon'

import api from 'app/modules/api';

import 'assets/css/customers.scss';

const Wrapper = ({children,customer,setCustomer,loading,customers,label_callback=(v)=>v.url})=>{
  const [filter,setFilter] = useState('');
  const [sidebar,setSidebar] = useState(!customer);

  return (
    <div className="customers">
      <div className={"customers__sidebar "+(sidebar?' customers__sidebar--active':'')}>
      <div className="customers__sidebar__toggler" onClick={()=>{setSidebar(!sidebar)}}><Icon name={sidebar?'keyboard_arrow_left':'keyboard_arrow_right'}/></div>
        <div className="customers__sidebar__inner">
          <div className="customers__sidebar__search">
            <Form.Group controlId="search">
              <Form.Control type="search" placeholder="Filter" onChange={(e)=>setFilter(e.target.value)} autoComplete="off"/>
            </Form.Group>
          </div>
          {loading && (
            <div className="text-center">
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                variant="dark"
              />
            </div>
          )}
          {!loading&&(
            <ListGroup as="ul" variant="flush">
              {!!document.lastCustomer && !customer && !filter && (
                <ListGroup.Item as="li" key={'customer_last'} onClick={()=>{setCustomer(document.lastCustomer);setSidebar(false)}} className={'text-truncate customers__item customers__item--last'}>
                  {document.lastCustomer.url}
                </ListGroup.Item>
              )}
              {customers.filter(v=>{
                return v.url.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
              }).map((v,k)=>{
                return (
                  <ListGroup.Item as="li" key={'customer_'+v.id} active={customer.id===v.id} onClick={()=>{setCustomer(v);setSidebar(false)}} className={'text-truncate customers__item'}>
                    {label_callback(v,customer.id===v.id)}
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          )}
        </div>
      </div>
      <div className="customers__content">
        {!!customer&&children}
        {!customer&&(
          <Container className="my-5">
            <Alert variant="info">Bitte wähle einen Kunden aus.</Alert>
            <LinkContainer to="/app/customers/info/new"><Button>Neuer Kunde</Button></LinkContainer>
          </Container>
        )}
      </div>
    </div>
  )
}

const Customers = ()=>{
  const [customers,setCustomers] = useState([]);
  const [customer,setCustomer] = useState(false);
  const [loading,setLoading] = useState(false);

  useEffect(()=>{
    loadCustomers();
  },[]);

  const loadCustomers = async ()=>{
    setLoading(true);
    const resp = await api.send('customers/list',{limit:0});
    setCustomers(resp.content.data);
    setLoading(false);
  }

  useEffect(()=>{
    if(customer){
      document.lastCustomer = customer;
    }
  },[customer]);

  return (
    <Switch>
      <Route exact path="/app/customers/info">
        <Wrapper customers={customers} loading={loading} customer={customer} setCustomer={setCustomer}><CustomersInfo customer={customer} loadCustomers={loadCustomers} setCustomer={setCustomer}/></Wrapper>
      </Route>
      <Route exact path="/app/customers/info/new">
        <CustomersNew customer={customer} setCustomer={setCustomer} />
      </Route>
      <Route exact path="/app/customers/timetracking">
        <Wrapper label_callback={(v,active=false)=>{
          if(v.timetracking > 0 || v.price > 0){
            return (
              <Media>
                <Media.Body>
                  {v.url}
                </Media.Body>
                {active?(
                  <span className="text-light"><Icon name="info"/></span>
                ):(
                  <span className="text-info"><Icon name="info"/></span>
                )}
              </Media>
            )
          }
          return v.url;
        }} customers={customers} loading={loading} customer={customer} setCustomer={setCustomer}><CustomersTimetracking customer={customer} loadCustomers={loadCustomers} customers={customers}/></Wrapper>
      </Route>
      <Route exact path="/app/customers/database">
        <Wrapper label_callback={(v,active=false)=>{
          if(v.backups > 0){
            return (
              <Media>
                <Media.Body>
                  {v.url}
                </Media.Body>
                {active?(
                  <span className="text-light"><Icon name="info"/></span>
                ):(
                  <span className="text-info"><Icon name="info"/></span>
                )}
              </Media>
            )
          }
          return v.url;
        }} customers={customers} loading={loading} customer={customer} setCustomer={setCustomer}><CustomersDatabase customer={customer}/></Wrapper>
      </Route>
      <Route exact path="/app/customers/credentials">
        <Wrapper customers={customers} loading={loading} customer={customer} setCustomer={setCustomer}><CustomersCredentials customer={customer}/></Wrapper>
      </Route>
      <Route path="*">
        <NoMatch nowrapper />
      </Route>
    </Switch>
  )
}

export default Customers