import React,{useEffect,useState} from 'react';
import moment from 'moment';

import api from 'app/modules/api';

import Icon from 'app/elements/Icon';
import Header from 'app/elements/Header';
import Pagination from 'app/elements/Pagination';

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'
import Spinner from 'react-bootstrap/Spinner'
import Media from 'react-bootstrap/Media'
import Accordion from 'react-bootstrap/Accordion'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

import "assets/css/react-widgets.scss";
import 'assets/css/week.scss';

moment.locale('de');

const getDuration = (a) => {
  let s = Math.abs(a);
  let minutes = s / 60;
  let hours   = Math.floor(minutes / 60);
  minutes = Math.round(minutes - (hours*60));
  return (a<0?'-':'')+hours+'h '+minutes+'m';
}
const getTime = (s,date=false)=>{
  const t = moment(s);
  return t.format(date?'L':'LT');
}

export const Status = ({reload,onReload})=>{
  const [loading,setLoading] = useState(false);
  const [status,setStatus] = useState(false);
  const [admin,setAdmin] = useState(false);

  const loadStatus = async ()=>{
    setLoading(true);
    const a = await api.send('user/admin');
    setAdmin(a.content)
    let status = await api.send('timelog/status');
    setStatus(status.content)
    setLoading(false);
  }

  const toggle = async (start)=>{
    setLoading(true);
    if(start){
      await api.send('timelog/start');
    }else{
      await api.send('timelog/stop');
      if(reload){
        reload();
      }else{
        loadStatus();
      }
    }
    loadStatus();
  }

  useEffect(()=>{
    loadStatus();
    if(onReload){
      let rlfunction = onReload(loadStatus);
      return ()=>{
        onReload(rlfunction,false);
      }
    }
  },[]);

  if(loading){
    return (
      <div className="text-center">
        <Spinner
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
          variant="light"
        />
      </div>
    )
  }

  return (
    <Card>
      {!!admin&&(
        <ListGroup variant="flush">
          {status.map(item=>(
            <>
              <ListGroup.Item>
                <Media>
                  <Media.Body><strong>{item.name}</strong></Media.Body>
                  <strong>{getDuration(item.time)}</strong>
                </Media>
                <Media>
                  <Media.Body>Arbeitswoche:</Media.Body>
                  <strong>{getDuration(item.weektime_done)} / {getDuration(item.weektime)}</strong>
                </Media>
              </ListGroup.Item>
            </>
          ))}
        </ListGroup>
      )}
      {!admin&&(
        <>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <Media>
                <Media.Body>Heutige Arbeitszeit:</Media.Body>
                <strong>{getDuration(status.today)}</strong>
              </Media>
            </ListGroup.Item>
            <ListGroup.Item>
              <Media>
                <Media.Body>Überstunden:</Media.Body>
                <strong>{getDuration(status.time)}</strong>
              </Media>
            </ListGroup.Item>
            <ListGroup.Item>
              <Media>
                <Media.Body>Arbeitswoche:</Media.Body>
                <strong>{getDuration(status.weektime_done)} / {getDuration(status.weektime)}</strong>
              </Media>
              <div className="week">
                <div className="week__inner" style={{width: (status.weektime_done/status.weektime*100)+ '%'}}></div>
                <div className="week__day week__day--1"></div>
                <div className="week__day week__day--2"></div>
                <div className="week__day week__day--3"></div>
                <div className="week__day week__day--4"></div>
              </div>
            </ListGroup.Item>
            {!!status.working&&(
              <ListGroup.Item>
                <Media>
                  <Media.Body>Gestartet seit:</Media.Body>
                  <strong>{moment(status.started_since).format('LLL')}</strong>
                </Media>
              </ListGroup.Item>
            )}
          </ListGroup>
          <Card.Footer>
            <Media>
              <Media.Body>
                {status.working?(
                  <Button onClick={()=>toggle(false)} variant={'pink'}><Icon name="pause"/> Stoppen</Button>
                ):(
                  <Button onClick={()=>toggle(true)} variant={'dark'}><Icon name="play_arrow"/> Starten</Button>
                )}
              </Media.Body>
              <Button onClick={()=>loadStatus()} variant={'secondary'}><Icon name={'sync'}/></Button>
            </Media>
          </Card.Footer>
        </>
      )}
    </Card>
  )
}

const List = ({reload,onReload,setDel})=>{
  const limit = 20;
  const [loading,setLoading] = useState(false);
  const [loadEdit,setLoadEdit] = useState(false);
  const [page,setPage] = useState(1);
  const [content,setContent] = useState(false);

  useEffect(()=>{
    loadList();
  },[page]);
  useEffect(()=>{
    let rlfunction = onReload(loadList);
    return ()=>{
      onReload(rlfunction,false);
    }
  },[])

  const loadList = async ()=>{
    setLoading(true);
    const resp = await api.send('timelog/list',{limit,offset: (page-1)*limit});
    setContent(resp.content);
    setLoading(false);
  }

  if(loading || !content){
    return (
      <div className="text-center">
        <Spinner
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
          variant="light"
        />
      </div>
    )
  }

  return (
    <>
      <Accordion>
        {content.items.map((v,k)=>(
          <ListItem  key={'timelog_'+v.id} v={v} setDel={setDel} setLoadEdit={setLoadEdit} loadEdit={loadEdit} reload={reload}></ListItem>
        ))}
      </Accordion>
      <Pagination
        className={'mt-3'}
        items={content.count}
        limit={limit}
        page={page}
        changePage={setPage}
      />
    </>
  )
}

const ListItem = ({v,setDel,setLoadEdit,loadEdit,reload})=>{

  let startTemp = new Date(v.start);
  let stopTemp = new Date(v.stop);
  startTemp.setMinutes(startTemp.getMinutes() - startTemp.getTimezoneOffset());
  stopTemp.setMinutes(stopTemp.getMinutes() - stopTemp.getTimezoneOffset());

  const [start,setStart] = useState(startTemp.toISOString().slice(0,16));
  const [stop,setStop] = useState(stopTemp.toISOString().slice(0,16));

  const submitEdit = async (e,id)=>{
    e.preventDefault();
    setLoadEdit(true);
    await api.send('timelog/edit',{id,start:moment(start).format('YYYY-MM-DD HH:mm:ss'),stop:moment(stop).format('YYYY-MM-DD HH:mm:ss')});
    reload();
    setLoadEdit(false);
  }

  return (
    <Card>
      <Card.Header>
        <Media>
          <Media.Body>{getTime(v.start,true)+' '+getTime(v.start)+' - '+getTime(v.stop)}</Media.Body>
          <ButtonGroup>
            <Accordion.Toggle as={Button} variant="secondary" eventKey={'timelog_'+v.id} size="sm">
              <Icon name="settings"/>
            </Accordion.Toggle>
            <Button size="sm" variant="secondary" onClick={()=>setDel(v.id)}><Icon name="delete"/></Button>
          </ButtonGroup>
        </Media>
      </Card.Header>
      <Accordion.Collapse eventKey={'timelog_'+v.id}>
        <Card.Body>
          <Form onSubmit={e=>submitEdit(e,v.id)}>
            <Form.Group>
              <Form.Label>Start</Form.Label>
              <Form.Control
                name="start"
                type="datetime-local"
                disabled={loadEdit}
                value={start}
                onChange={e=>setStart(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Ende</Form.Label>
              <Form.Control
                name="stop"
                type="datetime-local"
                disabled={loadEdit}
                value={stop}
                onChange={e=>setStop(e.target.value)}
              />
            </Form.Group>
            <Button disabled={loadEdit} type="submit">Speichern</Button>
          </Form>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  )
}

let reloadFcts = [];
const Timelog = ()=>{
  const [del,setDel] = useState(false);

  const reload = ()=>{
    reloadFcts.forEach(v=>{
      if(v){v()}
    })
  }
  const onReload = (fct,status=true)=>{
    if(status){
      reloadFcts.push(fct);
      return reloadFcts.length - 1;
    }else{
      reloadFcts.splice(fct,1);
    }
  }
  const closeDeleteModal = ()=>{
    setDel(false);
  }
  const deleteItem = async ()=>{
    const item_id = del;
    setDel(false);
    await api.send('timelog/remove',{item_id});
    reload();
  }

  return (
    <>
      <Header>
        <Container>
          <h2><Icon name="access_time"/> Timelog</h2>
        </Container>
      </Header>
      <Container>
        <Row>
          <Col xl={5} className={'mb-3 mb-xl-0'}>
            <Status reload={reload} onReload={onReload}/>
          </Col>
          <Col>
            <List reload={reload} onReload={onReload} setDel={setDel}/>
          </Col>
        </Row>
      </Container>
      <Modal show={!!del} onHide={closeDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Daten löschen?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Die Daten können nicht wiederhergestellt werden.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteModal}>
            Abbrechen
          </Button>
          <Button variant="danger" onClick={deleteItem}>
            Löschen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Timelog;