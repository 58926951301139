import React from 'react';

import Pagination from 'react-bootstrap/Pagination'

const Pag = ({items,limit,page,maxItems=5,changePage=()=>{},className=''})=>{
  if(!items || !limit || !page){
    return (null);
  }
  let pages = Math.ceil(items / limit);
  if(page > pages || page < 0 || pages === 1){
    return (null);
  }

  maxItems = Math.min(maxItems,pages);

  const getItems = ()=>{
    let items = [];

    let start = Math.max(1,Math.min((pages+1) - maxItems,page - Math.floor(maxItems/2)))
    for(let i=0;i<maxItems;i++){
      let item = start + i;
      items.push(
        <Pagination.Item onClick={()=>changePage(item)} active={item===page} key={'pag_'+item}>{item}</Pagination.Item>
      )
    }

    return items;
  }

  return (
    <Pagination style={{'justify-content': 'center'}} className={className}>
      {page!==1&&(
        <>
          <Pagination.First onClick={()=>changePage(1)}/>
          <Pagination.Prev onClick={()=>changePage(page-1)}/>
        </>
      )}

      {getItems()}

      {pages!==page&&(
        <>
          <Pagination.Next onClick={()=>changePage(page+1)} />
          <Pagination.Last onClick={()=>changePage(pages)}/>
        </>
      )}
    </Pagination>
  )
}

export default Pag;