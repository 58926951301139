import React,{useState} from 'react';
import { useHistory } from "react-router-dom";

import api from 'app/modules/api';

import Icon from 'app/elements/Icon';
import Header from 'app/elements/Header';

import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'

const CustomersNew = ({setCustomer})=>{
  let history = useHistory();

  const [loading,setLoading] = useState(false);
  const [error,setError] = useState(false);

  const submit = async (e)=>{
    e.preventDefault();
    setLoading(true);
    setError(false);
    const resp = await api.send('customers/add',{url:e.target.url.value,basecamp:e.target.basecamp.value});
    if(resp.error === 0){
      setCustomer(resp.content);
      history.push("/app/customers/info");
    }else{
      setError(true);
      setLoading(false);
    }
  }

  return (
    <>
      <Header>
        <Container>
          <h2><Icon name="face"/> Kunde anlegen</h2>
        </Container>
      </Header>
      <Container>
        {error&&(
          <Alert variant={'danger'}>Der eingegebene Kunde existiert bereits.</Alert>
        )}
        <Form onSubmit={submit}>
          <Card>
            <Card.Header>
              Neuer Kunde
            </Card.Header>
            <Card.Body>
              <Form.Group controlId="url">
                <Form.Label>Web-Adresse</Form.Label>
                <Form.Control type="text" required disabled={loading} autoComplete="off"/>
              </Form.Group>
              <Form.Group controlId="basecamp">
                <Form.Label>Basecamp</Form.Label>
                <Form.Control type="text" disabled={loading} autoComplete="off"/>
              </Form.Group>
            </Card.Body>
            <Card.Footer>
              <Button  variant="primary" type="submit" disabled={loading}>Speichern</Button>
            </Card.Footer>
          </Card>
        </Form>
      </Container>
    </>
  )
}

export default CustomersNew;